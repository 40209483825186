import React, { useState } from "react";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const notyf = new Notyf();

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    telegram: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const isPhoneNumberSuspicious = (phone) => {
    const phoneWithoutSpaces = phone.replace(/\s+/g, ""); // Убираем пробелы
    const regex = /(\d)\1{5,}/; // Проверка на подряд идущие одинаковые цифры (например: 777777, 999999)
    return regex.test(phoneWithoutSpaces);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.phone || !isValidPhoneNumber(formData.phone)) {
      setError("Пожалуйста, введите корректный номер телефона.");
      return;
    }
    if (isPhoneNumberSuspicious(formData.phone)) {
      setError(
        "Номер телефона выглядит подозрительным. Пожалуйста, введите другой."
      );
      return; // Прерываем отправку формы
    }

    setLoading(true);

    try {
      const response = await fetch("/api/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData }),
      });

      const result = await response.json();

      if (response.ok) {
        notyf.success("Данные успешно отправлены!");
        setFormData({ name: "", phone: "", telegram: "", message: "" }); // Очистка формы
        ym(99037244, "reachGoal", "formDone");
      } else {
        notyf.error(result.error || "Ошибка отправки данных.");
      }
    } catch (error) {
      console.error("Ошибка отправки формы:", error);
      notyf.error("Ошибка отправки данных.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <section id="contacts" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8 items-center">
        {/* Левая часть: Форма */}
        <div className="bg-[#140c1c] rounded-lg p-8 shadow-lg">
          <h2 className="text-3xl sm:text-4xl font-bold mb-4 text-purple-400">
            Давайте начнем работать вместе!
          </h2>
          <p className="text-gray-400 mb-8">
            Мы создаем передовые IT-решения, которые упрощают сложные задачи и
            помогают вашему бизнесу расти.
          </p>
          <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Ваше имя"
                required
                className="w-full px-4 py-3 rounded-lg bg-[#1c1a35] text-gray-300 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
              <div className="w-full">
                <PhoneInput
                  international
                  defaultCountry="RU"
                  value={formData.phone}
                  onChange={(value) =>
                    setFormData((prevData) => ({ ...prevData, phone: value }))
                  }
                  name="phone"
                  required
                  className="phone-input w-full px-4 py-3 rounded-lg bg-[#1c1a35] text-gray-200 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                />
              </div>
            </div>
            <div className="mb-4">
              <input
                type="text"
                name="telegram"
                value={formData.telegram}
                onChange={handleChange}
                placeholder="Ваш Telegram (по желанию)"
                className="w-full px-4 py-3 rounded-lg bg-[#1c1a35] text-gray-300 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-[#844df5] hover:from-purple-400 hover:to-purple-600 text-white font-bold py-3 rounded-lg shadow-lg transition-all"
            >
              Отправить
            </button>
            {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
          </form>
        </div>

        {/* Правая часть: Контакты */}
        <div className="text-gray-400 lg:ml-24 flex flex-col gap-8">
          <div>
            <h3 className="text-lg font-bold text-purple-400 mb-4">Телефон</h3>
            <div className="flex items-center gap-4">
              <div className="bg-[#844df5] rounded-full pt-3 pb-3 px-4">
                <i className="fas fa-phone-alt text-white text-lg"></i>
              </div>
              <p className="text-lg">
                <a
                  href="tel:+79953085888"
                  className="text-blue-400 hover:underline"
                >
                  +7 995 308 58 88
                </a>
              </p>
            </div>
          </div>
          <div>
            <h3 className="text-lg font-bold text-purple-400 mb-4">Telegram</h3>
            <div className="flex items-center gap-4">
              <div className="bg-[#844df5] rounded-full pt-3 pb-3 px-4">
                <i className="fab fa-telegram-plane text-white text-lg"></i>
              </div>
              <p className="text-lg">
                <a
                  href="https://t.me/netsky_dev"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-400 hover:underline"
                >
                  @netsky_dev
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;
