import React, { useState } from "react";

const StartupFeatures = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      title: "Создание MVP",
      description:
        "Мы разрабатываем минимально жизнеспособный продукт (MVP), чтобы вы могли быстро протестировать свою идею на рынке.",
    },
    {
      title: "Индивидуальный подход",
      description:
        "Каждый проект уникален, и мы адаптируем наше решение под вашу идею и бизнес-модель.",
    },
    {
      title: "UX/UI Дизайн",
      description:
        "Создаём удобный и современный интерфейс, чтобы ваш продукт привлекал пользователей и обеспечивал отличный пользовательский опыт.",
    },
    {
      title: "Интеграция технологий",
      description:
        "Мы используем передовые технологии и инструменты для разработки вашего стартапа: от облачных решений до блокчейна.",
    },
    {
      title: "Масштабируемость",
      description:
        "Готовим ваш продукт к росту и увеличению нагрузки, чтобы он мог масштабироваться вместе с вашим бизнесом.",
    },
    {
      title: "Консультации и поддержка",
      description:
        "Помогаем вам на всех этапах разработки: от планирования до поддержки и улучшения продукта после запуска.",
    },
    {
      title: "Оптимизация затрат",
      description:
        "Оптимизируем бюджет, чтобы вы получили максимум функционала за разумные деньги.",
    },
  ];

  return (
    <div id="services" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Возможности для стартапов</h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Мы превращаем идеи в успешные продукты, помогая стартапам на каждом этапе их развития.
          </p>
        </div>

        {/* Список решений */}
        <div>
          {solutions.map((solution, index) => (
            <div
              key={index}
              className={`relative flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 transition ${
                activeIndex === index
                  ? "bg-gradient-to-tr from-[#27134f] to-[#844df5] text-white rounded-lg"
                  : "border-b border-gray-700"
              }`}
              onMouseEnter={() => setActiveIndex(index)}
            >
              {/* Номер и текст */}
              <div className="sm:w-full">
                <h3
                  className={`text-2xl font-bold mb-2 ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  }`}
                >
                  0{index + 1} {solution.title}
                </h3>
                <p
                  className={`text-sm ${
                    activeIndex === index ? "text-gray-200" : "text-gray-400"
                  }`}
                >
                  {solution.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default StartupFeatures;
