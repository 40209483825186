import React from "react";

const technologies = [
  { name: "Java", icon: "/img/icons/java.svg" },
  { name: "Python", icon: "/img/icons/python.svg" },
  { name: "React", icon: "/img/icons/react.svg" },
  { name: "Nestjs", icon: "/img/icons/nest.svg" },
  { name: "jRuby", icon: "/img/icons/jruby.svg" },
  { name: "PHP", icon: "/img/icons/php.svg" },
];

const TechStack = () => {
  return (
    <section id="technologies" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto text-center">
        {/* Заголовок */}
        <h2 className="text-4xl font-bold mb-4 text-purple-400">
          Наш стек технологий
        </h2>
        <p className="text-gray-400 text-lg mb-12 max-w-2xl mx-auto">
          Мы используем передовые технологии для разработки надежных и
          масштабируемых решений, которые помогают вашему бизнесу развиваться.
        </p>

        {/* Сетка технологий */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-6">
          {technologies.map((tech, index) => (
            <div
              key={index}
              className="group relative bg-[#140c1c] rounded-lg shadow-lg p-6 flex flex-col items-center justify-center transition transform hover:-translate-y-2 hover:bg-purple-800"
            >
              {/* Иконка технологии */}
              <img
                src={tech.icon}
                alt={tech.name}
                className="w-12 h-12 mb-4 group-hover:scale-110 transition-transform duration-300"
              />
              {/* Название технологии */}
              <span className="text-sm font-semibold group-hover:text-purple-400">
                {tech.name}
              </span>
              {/* Hover-эффект рамки */}
              <div className="absolute inset-0 rounded-lg border-2 border-purple-400 opacity-0 group-hover:opacity-100 transition-opacity"></div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TechStack;
