import React from "react";

const AboutMe = () => {
  return (
    <section className="bg-[#0F0715] py-20 px-6">
      <div className="container mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-12">
        {/* Левая колонка с фото */}
        <div className="w-full lg:w-1/2 relative">
          <div className="relative group">
            <img
              src="/img/dima.jpeg"
              alt="Дмитрий - основатель компании Netsky"
              className="rounded-2xl shadow-lg object-cover w-full h-auto"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent rounded-2xl"></div>
          </div>
        </div>

        {/* Правая колонка с текстом */}
        <div className="w-full lg:w-1/2 text-center lg:text-left">
          <h2 className="text-4xl font-extrabold text-purple-400 mb-4">
            Дмитрий, основатель <span className="text-white">Netsky</span>
          </h2>
          <p className="text-lg text-gray-300 leading-relaxed mb-6">
            Привет! Меня зовут Дмитрий, я основатель компании{" "}
            <span className="text-purple-400 font-semibold">Netsky</span>.
            Помимо управления компанией, я активно участвую в технической части
            как разработчик уровня Senior и Tech Lead. Мне 29 лет и мой стаж в IT-индустрии —
            более 6 лет.
          </p>
          <p className="text-lg text-gray-300 leading-relaxed mb-6">
            Моя главная задача — строить надежную архитектуру для ваших
            IT-решений. Я лично отвечаю за качество кода, организую работу
            команды разработчиков и внедряю передовые технологии.{" "}
            <span className="text-purple-400 font-semibold">Netsky</span> — это
            не просто компания, это команда профессионалов, которая помогает
            бизнесу достигать своих целей.
          </p>
          <p className="text-lg text-gray-300 leading-relaxed mb-6">
            Моё видение — это создание уникальных решений, которые оптимизируют
            процессы, повышают эффективность и помогают нашим клиентам расти. Мы
            гордимся тем, что предоставляем сервис мирового уровня.
          </p>

        </div>
      </div>
    </section>
  );
};

export default AboutMe;
