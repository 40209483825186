import React, { useState } from "react";

const CRMFeatures = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      title: "Централизация клиентских данных",
      description:
        "CRM собирает всю информацию о клиентах, заказах и взаимодействиях в единой системе, упрощая доступ и управление.",
    },
    {
      title: "Автоматизация процессов",
      description:
        "CRM автоматизирует управление продажами, маркетингом, обслуживанием клиентов и аналитикой.",
    },
    {
      title: "Аналитика и прогнозирование",
      description:
        "CRM предоставляет инструменты для анализа данных о клиентах и прогнозирования продаж, помогая улучшить стратегию.",
    },
    {
      title: "Управление продажами",
      description:
        "CRM помогает контролировать сделки на всех этапах, упрощая ведение клиентов и улучшая конверсию.",
    },
    {
      title: "Интеграция с другими системами",
      description:
        "CRM интегрируется с ERP, телефонией, почтой и другими инструментами для полного управления бизнес-процессами.",
    },
    {
      title: "Повышение лояльности клиентов",
      description:
        "CRM помогает отслеживать взаимодействие с клиентами, улучшая их опыт и укрепляя долгосрочные отношения.",
    },
    {
      title: "Снижение затрат",
      description:
        "CRM минимизирует ручной труд, снижая затраты на управление клиентами и маркетингом.",
    },
  ];

  return (
    <div id="services" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Возможности CRM-системы</h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            CRM-система — это мощный инструмент для управления клиентами и
            автоматизации процессов взаимодействия.
          </p>
        </div>

        {/* Список возможностей */}
        <div>
          {solutions.map((solution, index) => (
            <div
              key={index}
              className={`relative flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 transition ${
                activeIndex === index
                  ? "bg-gradient-to-tr from-[#27134f] to-[#844df5] text-white rounded-lg"
                  : "border-b border-gray-700"
              }`}
              onMouseEnter={() => setActiveIndex(index)}
            >
              {/* Номер и текст */}
              <div className="sm:w-full">
                <h3
                  className={`text-2xl font-bold mb-2 ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  }`}
                >
                  0{index + 1} {solution.title}
                </h3>
                <p
                  className={`text-sm ${
                    activeIndex === index ? "text-gray-200" : "text-gray-400"
                  }`}
                >
                  {solution.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CRMFeatures;
