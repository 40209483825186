import React, { useState } from "react";

const faqs = [
  {
    question: "Какова цена услуги по разработке ERP системы?",
    answer:
      "Цена разработки ERP системы начинается от 550 тысяч рублей. Стоимость зависит от сложности, функциональности и индивидуальных требований вашего бизнеса.",
  },
  {
    question: "Каковы преимущества услуги по разработке ERP системы с нуля?",
    answer:
      "Создание ERP системы с нуля позволяет адаптировать её под ваши уникальные бизнес-процессы, включая интеграции с существующими системами, гибкость масштабирования и улучшение производительности.",
  },
  {
    question: "Каковы сроки разработки ERP системы?",
    answer:
      "Средний срок разработки ERP системы составляет от 3 до 6 месяцев. Конкретные сроки зависят от объема задач, количества интеграций и других требований проекта.",
  },
  {
    question: "Какие интеграции можно реализовать в ERP системе?",
    answer:
      "ERP система может быть интегрирована с CRM, бухгалтерскими системами, системами управления складом, логистикой, платежными шлюзами, аналитическими платформами и многими другими решениями.",
  },
  {
    question: "Какой уровень поддержки и обслуживания предоставляется после создания ERP?",
    answer:
      "Мы предоставляем техническую поддержку 24/7, регулярные обновления, а также помощь в оптимизации и развитии вашей ERP системы после её внедрения.",
  },
  {
    question: "Как начать процесс создания ERP?",
    answer:
      "Свяжитесь с нами для обсуждения ваших задач и целей. Мы проведем аудит бизнес-процессов, подготовим техническое задание и начнем разработку вашей уникальной ERP системы.",
  },
];

const FAQAccordionERP = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-purple-400 mb-6">
          Часто задаваемые вопросы о разработке ERP
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-[#140c1c] rounded-lg shadow-lg overflow-hidden"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center px-6 py-4 text-left font-semibold text-lg hover:bg-[#27203e] transition"
              >
                <span>{faq.question}</span>
                <span>
                  {activeIndex === index ? (
                    <i className="fas fa-chevron-up text-purple-400"></i>
                  ) : (
                    <i className="fas fa-chevron-down text-purple-400"></i>
                  )}
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 text-gray-300">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordionERP;
