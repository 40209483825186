import React, { useState } from "react";
import ERPFeatures from "./ERPFeatures";
import Portfolio from "./Portfolio";
import Stats from "./Stats";
import Navbar from "./Navbar";
import TechStack from "./TechStack";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import FAQAccordionERP from "./FAQAccordionERP";
import AboutMe from "./AboutMe";
import QuizForm from "./QuizForm";
import ComparisonComponent from "./ComparisonComponent";

const HomePage = () => {
  return (
    <>
      <Navbar />
      {/* Блок с текстом и формой */}
      <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
        <div className="container mt-24 mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-8">
          {/* Левая колонка с текстом */}
          <div className="lg:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Комплексные IT-решения для бизнеса
            </h1>
            <h2 className="text-4xl sm:text-5xl font-bold text-[#844df5] mb-6">
              ERP-системы
              <br />
              для вашего бизнеса
            </h2>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Мы создаём ERP-системы, которые помогают автоматизировать ключевые
              бизнес-процессы, снизить издержки и повысить эффективность.
            </p>
            <ul className="list-disc list-inside text-sm sm:text-base mb-6 leading-relaxed font-light">
              <li>Централизация данных в единой системе.</li>
              <li>
                Автоматизация управления финансами, запасами и производством.
              </li>
              <li>Интеграция с существующими бизнес-системами.</li>
            </ul>
            {/* Кнопка (скрыта на мобильных устройствах) */}
            <a href="#projects" className="btn-outline hidden sm:inline-block">
              Узнать больше
            </a>
          </div>

          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <QuizForm />
          </div>
        </div>
      </div>
      <ERPFeatures />
      <ComparisonComponent route="/erp" />
      <Stats />
      <Portfolio />
      <AboutMe />
      <TechStack />
      <Testimonials />
      <FAQAccordionERP />
      <ContactUs />
    </>
  );
};

export default HomePage;
