import React, { useState } from "react";

const faqs = [
  {
    question: "Сколько стоит разработка интернет-магазина?",
    answer:
      "Стоимость разработки интернет-магазина начинается от 300 тысяч рублей. Цена зависит от объема работ, функционала и индивидуальных требований вашего проекта.",
  },
  {
    question: "Почему стоит выбрать индивидуальную разработку?",
    answer:
      "Индивидуальная разработка позволяет создать уникальный дизайн, гибкие интеграции с системами логистики и оплаты, а также гарантирует масштабируемость под ваш бизнес.",
  },
  {
    question: "Каковы сроки разработки интернет-магазина?",
    answer:
      "Средний срок разработки интернет-магазина составляет от 2 до 4 месяцев, в зависимости от сложности проекта и объема функционала.",
  },
  {
    question: "Какие интеграции можно добавить в интернет-магазин?",
    answer:
      "Мы можем интегрировать ваш магазин с платежными шлюзами, системами управления складом, курьерскими службами, аналитическими инструментами и маркетинговыми платформами.",
  },
  {
    question: "Какую техническую поддержку вы предоставляете?",
    answer:
      "Мы предоставляем поддержку 24/7, помощь в решении технических проблем, регулярные обновления и развитие функционала по мере роста вашего бизнеса.",
  },
  {
    question: "Как начать процесс разработки интернет-магазина?",
    answer:
      "Свяжитесь с нами, чтобы обсудить ваши потребности и задачи. Мы поможем определить стратегию, разработать техническое задание и начать процесс разработки вашего интернет-магазина.",
  },
];

const FAQAccordionEcommerce = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-purple-400 mb-6">
          Часто задаваемые вопросы о разработке интернет-магазинов
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-[#140c1c] rounded-lg shadow-lg overflow-hidden"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center px-6 py-4 text-left font-semibold text-lg hover:bg-[#27203e] transition"
              >
                <span>{faq.question}</span>
                <span>
                  {activeIndex === index ? (
                    <i className="fas fa-chevron-up text-purple-400"></i>
                  ) : (
                    <i className="fas fa-chevron-down text-purple-400"></i>
                  )}
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 text-gray-300">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordionEcommerce;
