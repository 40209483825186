import React, { useState } from "react";

const Web3Features = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      title: "Децентрализованное управление",
      description:
        "Создавайте системы с полной децентрализацией управления, которые работают на основе блокчейна и исключают единый центр контроля.",
    },
    {
      title: "Интеграция смарт-контрактов",
      description:
        "Автоматизируйте выполнение соглашений с помощью смарт-контрактов, обеспечивая прозрачность и надежность ваших операций.",
    },
    {
      title: "Токенизация активов",
      description:
        "Переведите ваши активы в цифровую форму, используя NFT и токены, чтобы открыть новые возможности для управления и торговли.",
    },
    {
      title: "Приватность и безопасность",
      description:
        "Web3 технологии предоставляют пользователям контроль над своими данными, защищая конфиденциальность и повышая безопасность.",
    },
    {
      title: "Интеграция с блокчейн-сетями",
      description:
        "Подключайтесь к популярным блокчейн-сетям, таким как Ethereum, Binance Smart Chain или Polygon, чтобы расширить функционал ваших приложений.",
    },
    {
      title: "Создание DAO (децентрализованных организаций)",
      description:
        "Управляйте проектами и сообществами с помощью DAO, обеспечивая прозрачность и демократичность принятия решений.",
    },
    {
      title: "Масштабируемость и низкие комиссии",
      description:
        "Используйте решения второго уровня (Layer 2) для достижения высокой производительности и минимизации транзакционных издержек.",
    },
  ];

  return (
    <div id="services" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Возможности Web3 технологий</h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Web3 открывает новые горизонты в построении децентрализованных приложений и инфраструктуры.
          </p>
        </div>

        {/* Список решений */}
        <div>
          {solutions.map((solution, index) => (
            <div
              key={index}
              className={`relative flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 transition ${
                activeIndex === index
                  ? "bg-gradient-to-tr from-[#27134f] to-[#844df5] text-white rounded-lg"
                  : "border-b border-gray-700"
              }`}
              onMouseEnter={() => setActiveIndex(index)}
            >
              {/* Номер и текст */}
              <div className="sm:w-full">
                <h3
                  className={`text-2xl font-bold mb-2 ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  }`}
                >
                  0{index + 1} {solution.title}
                </h3>
                <p
                  className={`text-sm ${
                    activeIndex === index ? "text-gray-200" : "text-gray-400"
                  }`}
                >
                  {solution.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Web3Features;
