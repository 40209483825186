import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { Link } from "react-scroll";
import "react-phone-number-input/style.css";
import { Notyf } from "notyf";
import "react-phone-number-input/style.css";
import "notyf/notyf.min.css";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const notyf = new Notyf();

  const [isOpen, setIsOpen] = useState(false);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const [phone, setPhone] = useState("");

  const isPhoneNumberSuspicious = (phone) => {
    const phoneWithoutSpaces = phone.replace(/\s+/g, ""); // Убираем пробелы
    const regex = /(\d)\1{5,}/; // Проверка на подряд идущие одинаковые цифры (например: 777777, 999999)
    return regex.test(phoneWithoutSpaces);
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();
    if (!phone || !isValidPhoneNumber(phone)) {
      notyf.error("Пожалуйста, введите корректный номер телефона.");
      return;
    }
    if (isPhoneNumberSuspicious(phone)) {
      notyf.error(
        "Номер телефона выглядит подозрительным. Пожалуйста, введите другой."
      );
      return; // Прерываем отправку формы
    }

    try {
      const dataToSend = { phone };
      const response = await axios.post("/api/send", dataToSend);

      if (response.data.success) {
        notyf.success("Форма успешно отправлена!");
        ym(99037244, "reachGoal", "formDone");
        setQuizModalOpen(true); // Открыть квиз
      } else {
        notyf.error("Произошла ошибка при отправке формы.");
      }
    } catch (err) {
      console.error(err);
      notyf.error("Не удалось отправить форму. Попробуйте позже.");
    } finally {
    }
  };

  const handleCloseMenu = () => setIsOpen(false);

  const handleCloseModal = () => {
    setQuizModalOpen(false);
  };
  return (
    <>
      <header className="bg-[#0f0715] px-4 text-white fixed w-full z-50 shadow-md">
        <div className="container max-w-7xl mx-auto py-4 flex justify-between items-center">
          {/* Логотип */}
          <div className="flex items-center">
            <img
              src="/img/logo/logo.png"
              alt="Logo"
              className="w-10 h-10 mr-2"
            />
            <span className="text-xl font-bold">Netsky</span>
          </div>
          {/* Центрированный контент */}
          <div className="flex w-full md:hidden justify-end space-x-2 mr-2">
            <a
              href="https://t.me/netsky_dev"
              target="_blank"
              rel="noopener noreferrer"
              className="text-lg font-semibold hover:text-purple-400 transition cursor-pointer"
            >
              <i className="fab fa-telegram text-xl"></i>
            </a>
          </div>
          {/* Навигация для ПК */}
          <nav
            className="hidden md:flex space-x-6 text-sm font-semibold"
            style={{ marginLeft: "8%" }}
          >
            <Link
              to="services"
              smooth={true}
              duration={500}
              className="hover:text-purple-400 transition cursor-pointer"
            >
              Сервисы
            </Link>
            <Link
              to="projects"
              smooth={true}
              duration={500}
              className="hover:text-purple-400 transition cursor-pointer"
            >
              Проекты
            </Link>
            <Link
              to="technologies"
              smooth={true}
              duration={500}
              className="hover:text-purple-400 transition cursor-pointer"
            >
              Технологии
            </Link>
            <Link
              to="reviews"
              smooth={true}
              duration={500}
              className="hover:text-purple-400 transition cursor-pointer"
            >
              Отзывы
            </Link>
            <Link
              to="contacts"
              smooth={true}
              duration={500}
              className="hover:text-purple-400 transition cursor-pointer"
            >
              Контакты
            </Link>
          </nav>

          {/* Кнопки и телефон */}
          <div className="hidden lg:flex w-1/5 items-center space-x-4">
            <form onSubmit={handlePhoneSubmit} className="flex items-center">
              <PhoneInput
                international
                defaultCountry="RU"
                value={phone}
                onChange={setPhone}
                name="phone"
                className="phone-input text-sm w-full px-4 py-2 rounded-lg bg-[#1c1a35] text-gray-200 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              />
              <button
                type="submit"
                className="px-4 py-2.5 ml-2 bg-[#844df5] text-white rounded-lg hover:bg-[#8757ea] transition flex items-center justify-center"
              >
                <i className="fas fa-paper-plane"></i> {/* Иконка отправки */}
              </button>
            </form>
          </div>

          {/* Бургер-меню */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="md:hidden text-xl focus:outline-none"
          >
            {isOpen ? (
              <i className="fas fa-times"></i>
            ) : (
              <i className="fas fa-bars"></i>
            )}
          </button>
        </div>

        {/* Мобильное меню */}
        {isOpen && (
          <div className="absolute top-0 left-0 w-full h-screen bg-[#0f0715] text-white flex flex-col items-center">
            {/* Первый слой меню */}
            <div className="w-full flex items-center justify-between px-6 py-4 border-b border-gray-600">
              <div className="flex items-center">
                <img
                  src="/img/logo/logo.png"
                  alt="Logo"
                  className="w-10 h-10 mr-2"
                />
                <span className="text-xl font-bold">Netsky</span>
              </div>
              <button
                onClick={() => setIsOpen(false)}
                className="text-2xl focus:outline-none"
              >
                <i className="fas fa-times"></i>
              </button>
            </div>

            {/* Основные ссылки */}
            <div className="flex flex-col items-center mt-6 space-y-4">
              <Link
                to="services"
                smooth={true}
                duration={500}
                onClick={handleCloseMenu}
                className="text-lg font-semibold hover:text-purple-400 transition cursor-pointer"
              >
                Сервисы
              </Link>
              <Link
                to="projects"
                smooth={true}
                duration={500}
                onClick={handleCloseMenu}
                className="text-lg font-semibold hover:text-purple-400 transition cursor-pointer"
              >
                Проекты
              </Link>
              <Link
                to="technologies"
                smooth={true}
                duration={500}
                onClick={handleCloseMenu}
                className="text-lg font-semibold hover:text-purple-400 transition cursor-pointer"
              >
                Технологии
              </Link>
              <Link
                to="reviews"
                smooth={true}
                duration={500}
                onClick={handleCloseMenu}
                className="text-lg font-semibold hover:text-purple-400 transition cursor-pointer"
              >
                Отзывы
              </Link>
              <Link
                to="contacts"
                smooth={true}
                duration={500}
                onClick={handleCloseMenu}
                className="text-lg font-semibold hover:text-purple-400 transition cursor-pointer"
              >
                Контакты
              </Link>
            </div>
          </div>
        )}
      </header>

      {/* Модальное окно с квизом */}
      {quizModalOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal} // Закрытие при клике вне модалки
        >
          <div
            className="bg-[#1a0f29] p-6 rounded-lg w-full max-w-md shadow-lg relative"
            onClick={(e) => e.stopPropagation()} // Остановка всплытия, чтобы не закрыть модалку при клике на неё
          >
            {/* Крестик для закрытия */}
            <button
              className="absolute top-4 right-4 text-gray-400 hover:text-gray-600 focus:outline-none"
              onClick={handleCloseModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            {/* Содержимое квиза */}
            <QuizFormHead initialPhone={phone} />
          </div>
        </div>
      )}

      {/* Второй слой: форма */}
      <div className="bg-[#1a0f29] px-4 py-4 fixed lg:hidden bottom-0 w-full z-40 shadow-md">
        <div className="container max-w-7xl mx-auto">
          {/* Лейбл над формой */}
          <label className="block text-sm text-center text-gray-300 mb-2">
            Обсудим ваш проект и начнем его разработку
          </label>
          <form
            onSubmit={handlePhoneSubmit}
            className="flex items-center space-x-2"
          >
            <PhoneInput
              international
              defaultCountry="RU"
              value={phone}
              onChange={setPhone}
              name="phone"
              className="phone-input text-sm w-full px-4 py-2 rounded-lg bg-[#1c1a35] text-gray-200 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
              required
            />
            <button
              type="submit"
              className="px-4 py-2.5 bg-[#844df5] text-white rounded-lg hover:bg-[#8757ea] transition flex items-center justify-center"
            >
              <i className="fas fa-paper-plane"></i> {/* Иконка отправки */}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

const QuizFormHead = ({ initialPhone }) => {
  const notyf = new Notyf();

  const [step, setStep] = useState(-1); // Начинаем с -1 для показа приветственного сообщения
  const [answers, setAnswers] = useState({});
  const [loading, setLoading] = useState(false);

  const questionsByRoute = {
    "/": [
      {
        question:
          "Какую основную задачу вы хотите решить с помощью нашего решения?",
        options: [
          "Оптимизация процессов",
          "Увеличение продаж",
          "Автоматизация",
        ],
      },
      {
        question: "Какой ваш приоритетный бюджет на проект?",
        options: [
          "До 500 тыс. руб.",
          "От 500 тыс. до 1 млн. руб.",
          "Более 1 млн. руб.",
        ],
      },
      {
        question: "Какие технологии для вас наиболее важны?",
        options: [
          "Web3",
          "API интеграции",
          "Облачные решения",
          "Все вышеперечисленное",
        ],
      },
    ],
    "/erp": [
      {
        question: "Какие процессы вы хотите оптимизировать с помощью ERP?",
        options: [
          "Управление ресурсами",
          "Планирование производства",
          "Финансовый учет",
        ],
      },
      {
        question: "Какой уровень внедрения ERP вам нужен?",
        options: [
          "Локальный (для одного отдела)",
          "Корпоративный (для всей компании)",
          "Глобальный (международный уровень)",
        ],
      },
      {
        question: "Какая ключевая задача вашей компании?",
        options: [
          "Сокращение затрат",
          "Повышение эффективности",
          "Контроль процессов",
          "Все вышеперечисленное",
        ],
      },
    ],
    "/crm": [
      {
        question: "Какую задачу должна решать CRM?",
        options: [
          "Управление клиентами",
          "Автоматизация продаж",
          "Маркетинговые кампании",
        ],
      },
      {
        question: "Какой уровень интеграции вам необходим?",
        options: [
          "Интеграция с телефонией",
          "Интеграция с сайтом",
          "Интеграция с другими системами",
          "Все вышеперечисленное",
        ],
      },
      {
        question: "Какой объем клиентской базы у вас сейчас?",
        options: [
          "До 1000 клиентов",
          "1-10 тысяч клиентов",
          "Более 10 тысяч клиентов",
        ],
      },
    ],
    "/wms": [
      {
        question: "Какие складские процессы вы хотите автоматизировать?",
        options: ["Учет товаров", "Инвентаризация", "Логистика"],
      },
      {
        question: "Какие устройства планируется использовать?",
        options: [
          "Сканеры штрих-кодов",
          "Мобильные устройства",
          "Робототехника",
        ],
      },
      {
        question: "Какая главная цель внедрения WMS-системы?",
        options: [
          "Сокращение ошибок",
          "Оптимизация логистики",
          "Увеличение скорости обработки заказов",
        ],
      },
    ],
    "/hrm": [
      {
        question: "Какая основная задача для HRM-системы?",
        options: ["Учет сотрудников", "Подбор кадров", "Обучение и развитие"],
      },
      {
        question: "Какую аналитику вы хотите получить от системы?",
        options: [
          "Текучесть кадров",
          "Эффективность обучения",
          "Показатели производительности",
        ],
      },
      {
        question: "Какие HR-процессы требуют автоматизации?",
        options: [
          "Документооборот",
          "Расчет зарплаты",
          "Оценка сотрудников",
          "Все вышеперечисленное",
        ],
      },
    ],
    "/btc": [
      {
        question: "Какой тип блокчейн-проекта вас интересует?",
        options: ["Криптоплатежи", "NFT-платформы", "Смарт-контракты"],
      },
      {
        question: "Какие сети вы хотите использовать?",
        options: ["Ethereum", "Binance Smart Chain", "Polygon"],
      },
      {
        question: "Какую основную цель вы преследуете?",
        options: [
          "Разработка нового продукта",
          "Интеграция блокчейн-решений",
          "Исследование технологии",
        ],
      },
    ],
    "/ecommerce": [
      {
        question: "Какой тип платформы вы хотите разработать?",
        options: ["Интернет-магазин", "Маркетплейс", "B2B портал"],
      },
      {
        question: "Какие функции для eCommerce наиболее важны?",
        options: [
          "Платежные системы",
          "Мобильное приложение",
          "Интеграция со складом",
          "Все вышеперечисленное",
        ],
      },
      {
        question: "Какой объем заказов вы обрабатываете?",
        options: [
          "До 1000 заказов в месяц",
          "1-10 тысяч заказов в месяц",
          "Более 10 тысяч заказов в месяц",
        ],
      },
    ],
    "/startup": [
      {
        question: "На каком этапе находится ваш стартап?",
        options: ["Идея", "MVP", "Готовый продукт"],
      },
      {
        question: "Какие ресурсы вам необходимы?",
        options: ["Финансирование", "Разработка", "Маркетинг"],
      },
      {
        question: "Какая основная цель вашего стартапа?",
        options: [
          "Запуск продукта",
          "Увеличение пользовательской базы",
          "Поиск инвесторов",
        ],
      },
    ],
  };
  const questions =
    questionsByRoute[location.pathname] || questionsByRoute["/"];

  const handleOptionClick = async (option) => {
    if (step === questions.length - 1) {
      // Сохраняем последний ответ перед сабмитом
      setAnswers((prev) => ({ ...prev, [step]: option }));
      await handleFinalSubmit(option); // Передаем последний ответ в сабмит
    } else {
      setAnswers((prev) => ({ ...prev, [step]: option }));
      setStep(step + 1);
    }
  };

  const handleFinalSubmit = async (lastAnswer) => {
    setLoading(true);

    try {
      const dataToSend = {
        phone: initialPhone,
        answers: { ...answers, [step]: lastAnswer }, // Добавляем последний ответ
      };

      // Отправка итоговых данных на сервер
      const response = await axios.post("/api/send", dataToSend);

      if (response.data.success) {
        notyf.success("Форма успешно отправлена!");

        // Показать сообщение об успешной отправке
        setStep(questions.length); // Устанавливаем шаг как завершенный

        // Закрыть модальное окно через 3 секунды
        setTimeout(() => {
          setQuizModalOpen(false);
        }, 3000);
      } else {
        notyf.error("Произошла ошибка при отправке формы.");
      }
    } catch (error) {
      console.error("Ошибка отправки формы:", error);
      notyf.error("Не удалось отправить форму. Попробуйте позже.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md p-6 rounded-lg shadow-md">
      {step === -1 ? ( // Приветственное сообщение
        <div className="text-start">
          <h3 className="text-xl font-bold text-purple-400 mb-4">
            Спасибо, ваш номер {initialPhone} уже записан!
          </h3>
          <p className="text-lg text-gray-300 mb-6">
            Мы скоро свяжемся с вами. По желанию вы можете пройти небольшой
            квиз, чтобы мы лучше понимали специфику вашего проекта.
          </p>
          <button
            onClick={() => setStep(0)}
            className="bg-purple-700 w-full text-center hover:bg-purple-600 text-white py-3 px-6 rounded-lg shadow transition"
          >
            Начать квиз
          </button>
        </div>
      ) : step < questions.length ? (
        <>
          <h3 className="text-2xl font-bold text-purple-400 mb-4">
            Вопрос {step + 1} из {questions.length}
          </h3>
          <p className="text-lg text-gray-300 mb-6">
            {questions[step].question}
          </p>
          <div className="grid gap-4">
            {questions[step].options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(option)}
                className="bg-purple-700 hover:bg-purple-600 text-white py-3 px-6 rounded-lg shadow transition"
              >
                {option}
              </button>
            ))}
          </div>
        </>
      ) : (
        <div className="text-center">
          <h3 className="text-2xl font-bold text-purple-400 mb-4">
            Спасибо за ваши ответы!
          </h3>
          <p className="text-lg text-gray-300">
            Мы уже записали ваш номер телефона и скоро свяжемся с вами.
          </p>
        </div>
      )}
    </div>
  );
};

export default Navbar;
