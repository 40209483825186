import React, { useState, useEffect } from "react";

const Portfolio = () => {
  const [selectedProject, setSelectedProject] = useState(null);

  const portfolioData = {
    agnks: {
      img: "/img/portfolio/gazprom.png",
      title: "АГНКС «Газпром»",
      desc: "Комплексное решение для цифровизации сети газонаполнительных компрессорных станций. Проект включает разработку ERP и CRM систем, мобильного и веб-приложений, а также интеграцию с картографическими сервисами.",
      details: [
        { key: "Категория", value: "ERP/CRM для энергетики" },
        { key: "Клиент", value: "Газпром" },
        {
          key: "Особенности",
          value:
            "Картографическая интеграция, Учет логистики, Аналитические модули",
        },
        { key: "Технологии", value: "Ruby on Rails, NestJS, Flutter, Next.js" },
      ],
      description:
        "АГНКС «Газпром» — это проект, направленный на цифровизацию сети газонаполнительных станций. Основные задачи включали разработку удобных инструментов управления станциями, улучшение взаимодействия с клиентами и предоставление аналитики для оптимизации бизнес-процессов.",
      story:
        "Проект начался с запроса на модернизацию текущей системы управления. Мы разработали архитектуру, которая объединила управление станциями, анализ данных и клиентские сервисы в единую платформу.",
      approach:
        "Наш подход включал детальный анализ бизнес-процессов, проектирование гибкой системы с возможностью масштабирования и разработку удобного интерфейса для конечных пользователей.",
      livePreview: "https://gazprom-agnks.ru",
    },
    randevu: {
      img: "/img/portfolio/randevu.png",
      title: "Randevu",
      desc: "Randevu — это мощная ERP/CRM-система, предназначенная для управления процессами записи клиентов и управления бизнесом в индустрии красоты.",
      details: [
        { key: "Категория", value: "ERP и CRM для индустрии красоты" },
        { key: "Клиент", value: "Randevu" },
        {
          key: "Особенности",
          value: "Мультистраны, Мультиязычность, Платежные интеграции",
        },
        {
          key: "Технологии",
          value: "Ruby on Rails, Flutter, React, PostgreSQL",
        },
      ],
      description:
        "Система включает мощный инструмент управления расписаниями сотрудников, возможность напоминания о записях через WhatsApp или SMS.",
      story:
        "Клиент обратился с задачей интеграции всех бизнес-процессов в одной системе. Мы разработали решение с учетом локализации для нескольких языков.",
      approach:
        "Мы обеспечили: интеграцию с платежными системами, поддержку нескольких валют и языков, создание удобного интерфейса.",
      livePreview: "https://randevu.beauty",
    },
    uppora: {
      img: "/img/portfolio/uppora.png",
      title: "Аппора",
      desc: "Аппора — это инновационная платформа донатов, которая позволяет поддерживать авторов и проекты легко, безопасно и без обязательной подписки.",
      details: [
        { key: "Категория", value: "Платформа донатов" },
        { key: "Клиент", value: "Uppora" },
        {
          key: "Особенности",
          value:
            "Быстрые переводы, Простота использования, Прозрачность операций",
        },
        {
          key: "Технологии",
          value: "Ruby on Rails, Vue.js, PostgreSQL, API интеграции",
        },
      ],
      description:
        "Аппора предоставляет пользователям возможность поддерживать любимых авторов и проекты без необходимости регистрации или подписки.",
      story:
        "Основная цель — предоставить простой и удобный способ поддерживать авторов и проекты, избавив пользователей от избыточных действий.",
      approach:
        "Мы создали прозрачную систему учета операций, удобный личный кабинет и интеграцию с платежными системами.",
      livePreview: "https://uppora.org",
    },
    vombat: {
      img: "/img/portfolio/vombat.png",
      title: "Vombat",
      desc: "Vombat — это интеллектуальная платформа для раннего выявления ошибок в интернет-рекламе.",
      details: [
        { key: "Категория", value: "Digital-реклама" },
        { key: "Клиент", value: "Vombat" },
        {
          key: "Особенности",
          value:
            "Аналитика рекламы, Раннее выявление ошибок, Интуитивный интерфейс",
        },
        {
          key: "Технологии",
          value: "Ruby on Rails, React, PostgreSQL, API интеграции",
        },
      ],
      description:
        "Мы присоединились к проекту на стадии первой версии, провели рефакторинг и добавили функционал.",
      story:
        "Помогли минимизировать ошибки в рекламе, улучшили систему и значительно расширили функционал.",
      approach:
        "Улучшение архитектуры, внедрение новых функций и интеграции с системами аналитики.",
      livePreview: "https://vombat.pro",
    },
    jubitom: {
      img: "/img/portfolio/jubitom.png",
      title: "Jubitom",
      desc: "Jubitom предоставляет широкий выбор ювелирных изделий и часов с интеграцией современных инструментов аналитики.",
      details: [
        { key: "Категория", value: "E-commerce" },
        { key: "Клиент", value: "Jubitom" },
        {
          key: "Особенности",
          value: "Интеграция Stripe API, Высокая производительность",
        },
        { key: "Технологии", value: "Php, Shopify, PostgreSQL, Stripe API" },
      ],
      description:
        "Jubitom объединяет функциональность интернет-магазина с аналитическими системами.",
      story:
        "Мы создали надежную платформу, поддерживающую высокий уровень активности клиентов.",
      approach:
        "Интеграции с аналитическими системами и API для обработки платежей.",
      livePreview: "https://jubitom.com",
    },
    tronboss: {
      img: "/img/portfolio/tronboss.png",
      title: "TRONBOSS",
      desc: "TRONBOSS предлагает простой способ арендовать энергию в сети TRON и минимизировать комиссии.",
      details: [
        { key: "Категория", value: "Криптовалютные приложения" },
        { key: "Клиент", value: "TRONBOSS" },
        {
          key: "Особенности",
          value: "Энергия для транзакций, Высокая производительность",
        },
        { key: "Технологии", value: "Java, NestJS, NuxtJS, MongoDB, Docker" },
      ],
      description:
        "TRONBOSS - инновационное решение для пользователей сети TRON с моментальной обработкой транзакций.",
      story:
        "Основная цель — создать высокопроизводительное и безопасное решение для криптовалютных транзакций.",
      approach:
        "Реализация моментальных транзакций и децентрализованного подхода с использованием современных технологий.",
      livePreview: null,
    },
  };

  const handleCardClick = (key) => {
    setSelectedProject(portfolioData[key]);
  };

  const closeModal = () => {
    setSelectedProject(null);
  };

  // Отключение прокрутки сайта и фиксация скролла
  useEffect(() => {
    if (selectedProject) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
    return () => {
      document.body.style.overflow = "";
    };
  }, [selectedProject]);

  const handleOverlayScroll = (e) => {
    const modalContent = document.getElementById("modal-content");
    if (!modalContent) return;

    // Проверяем, находится ли курсор над модальным окном
    const isInsideModal = modalContent.contains(e.target);

    // Если событие произошло внутри модалки, оно обрабатывается нативно
    if (!isInsideModal) {
      e.preventDefault();
      modalContent.scrollTo({
        top: modalContent.scrollTop + e.deltaY,
        behavior: "smooth", // Плавная прокрутка
      });
    }
  };

  return (
    <div id="projects" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Наши проекты</h2>
          <p className="text-gray-400 text-lg max-w-3xl mx-auto">
            Ознакомьтесь с нашими последними кейсами.
          </p>
        </div>

        {/* Карточки портфолио */}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {Object.entries(portfolioData).map(([key, project]) => (
            <div
              key={key}
              className="relative bg-gradient-to-tr from-[#27134f] to-[#844df5] rounded-lg overflow-hidden shadow-lg cursor-pointer hover:shadow-2xl transition"
              onClick={() => handleCardClick(key)}
            >
              <img
                src={project.img}
                alt={project.title}
                className="w-full h-72 object-cover"
              />
              <div className="p-6">
                <h3 className="text-xl font-bold text-purple-400">
                  {project.title}
                </h3>
                <p className="text-sm text-gray-300 mt-2">{project.desc}</p>
                <button
                  onClick={(e) => {
                    e.stopPropagation(); // Чтобы клик не закрывал модалку
                    handleCardClick(key); // Открываем модалку для выбранного проекта
                  }}
                  className="mt-4 inline-block text-sm text-purple-300 hover:text-purple-500 transition"
                >
                  Подробнее
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Модальное окно */}
      {selectedProject && (
        <div
          className="fixed inset-0 bg-black bg-opacity-75 z-50 flex items-center justify-center"
          onWheel={handleOverlayScroll} // Перехватываем событие прокрутки
          onClick={closeModal}
        >
          <div
            id="modal-content"
            className="relative bg-white text-black w-full max-w-4xl max-h-[90vh] p-8 rounded-lg overflow-y-auto"
            onClick={(e) => e.stopPropagation()} // Остановка кликов внутри модалки
          >
            <button
              className="absolute top-4 right-4 w-10 h-10 bg-purple-600 text-white rounded-lg flex items-center justify-center hover:bg-purple-700 transition"
              onClick={closeModal}
            >
              ✕
            </button>

            {/* Картинка сверху */}
            <img
              src={selectedProject.img}
              alt={selectedProject.title}
              className="rounded-lg shadow-lg w-full mb-6"
            />

            {/* Основной контент */}
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div>
                <h3 className="text-3xl font-bold mb-4">
                  {selectedProject.title}
                </h3>
                <p className="text-lg mb-4">{selectedProject.desc}</p>
              </div>
              <div className="text-sm text-gray-700 space-y-2">
                {selectedProject.details.map((detail, idx) => (
                  <div key={idx}>
                    <strong>{detail.key}:</strong> {detail.value}
                  </div>
                ))}
              </div>
            </div>

            <div className="mt-8">
              <h4 className="text-2xl font-bold mb-2">О проекте</h4>
              <p className="text-gray-700 mb-4">
                {selectedProject.description}
              </p>

              <h4 className="text-2xl font-bold mb-2">История</h4>
              <p className="text-gray-700 mb-4">{selectedProject.story}</p>

              <h4 className="text-2xl font-bold mb-2">Наш подход</h4>
              <p className="text-gray-700">{selectedProject.approach}</p>

              {selectedProject.livePreview && (
                <a
                  href={selectedProject.livePreview}
                  target="_blank"
                  className="mt-6 inline-block bg-purple-600 text-white px-6 py-3 rounded-md font-medium hover:bg-purple-500 transition"
                >
                  Перейти на сайт
                </a>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Portfolio;
