import React, { useState } from "react";

const IndexFeatures = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      number: "01",
      title: "ERP системы",
      description:
        "Разработка ERP-систем для автоматизации финансов, закупок, производства и других ключевых процессов вашей компании.",
    },
    {
      number: "02",
      title: "CRM платформы",
      description:
        "Мы разрабатываем кастомные CRM-решения для автоматизации управления продажами, клиентским сервисом и аналитикой.",
    },
    {
      number: "03",
      title: "Оптимизация логистики",
      description:
        "Разработка решений для управления складом, планирования поставок и повышения эффективности логистических операций.",
    },
    {
      number: "04",
      title: "HRM системы",
      description:
        "Автоматизация процессов управления персоналом, включая рекрутинг, кадровый учет и расчет зарплаты.",
    },
    {
      number: "05",
      title: "E-commerce платформы",
      description:
        "Разработка платформ для автоматизации продаж и управления B2B-операциями, интеграция с CRM и ERP.",
    },
    {
      number: "06",
      title: "BI системы",
      description:
        "Построение аналитических платформ для сбора данных, создания BI-дэшбордов и поддержки прогнозирования.",
    },
    {
      number: "07",
      title: "Web3 и Блокчейн Решения",
      description:
        "Разработка децентрализованных приложений, интеграция блокчейнов и создание решений для DeFi и NFT.",
    },
  ];

  return (
    <div id="services" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Наши решения для бизнеса</h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Мы создаем индивидуальные IT-решения для крупных компаний, помогая
            оптимизировать процессы, управлять ресурсами и достигать ваших
            бизнес-целей.
          </p>
        </div>

        {/* Список решений */}
        <div>
          {solutions.map((solution, index) => (
            <div
              key={index}
              className={`relative flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 transition ${
                activeIndex === index
                  ? "bg-gradient-to-tr from-[#27134f] to-[#844df5] text-white rounded-lg"
                  : "border-b border-gray-700"
              }`}
              onMouseEnter={() => setActiveIndex(index)}
            >
              <div className="flex items-center sm:w-full">
                <span
                  className={`text-4xl font-bold ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  } mr-4`}
                >
                  {solution.number}
                </span>
                <h3
                  className={`text-2xl font-bold ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  }`}
                >
                  {solution.title}
                </h3>
              </div>
              <p
                className={`text-sm ${
                  activeIndex === index ? "text-gray-200" : "text-gray-400"
                } sm:w-2/3 mt-4 sm:mt-0`}
              >
                {solution.description}{" "}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default IndexFeatures;
