import React, { useState } from "react";

const faqs = [
  {
    question: "Какова цена услуги по разработке HRM системы?",
    answer:
      "Цена разработки HRM системы начинается от 400 тысяч рублей. Стоимость зависит от сложности, функциональности и индивидуальных требований вашего бизнеса.",
  },
  {
    question: "Каковы преимущества услуги по разработке HRM системы с нуля?",
    answer:
      "Создание HRM системы с нуля позволяет учитывать уникальные потребности управления персоналом, включая гибкость в настройке процессов найма, обучения и оценки сотрудников.",
  },
  {
    question: "Каковы сроки разработки HRM системы?",
    answer:
      "Средний срок разработки HRM системы составляет от 2 до 4 месяцев. Конкретные сроки зависят от объёма задач, функциональных требований и уровня кастомизации.",
  },
  {
    question: "Какие интеграции можно реализовать в HRM системе?",
    answer:
      "HRM система может быть интегрирована с системами бухгалтерии, управления заработной платой, аналитическими платформами, системами планирования ресурсов и другими решениями.",
  },
  {
    question: "Какой уровень поддержки и обслуживания предоставляется после создания HRM?",
    answer:
      "Мы предоставляем круглосуточную техническую поддержку, регулярные обновления и помощь в оптимизации вашей HRM системы, чтобы она соответствовала изменениям в бизнес-процессах.",
  },
  {
    question: "Как начать процесс создания HRM?",
    answer:
      "Свяжитесь с нами для обсуждения ваших целей и задач. Мы проведём аудит текущих процессов, подготовим детальное техническое задание и начнём разработку вашей HRM системы.",
  },
];

const FAQAccordionHRM = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-purple-400 mb-6">
          Часто задаваемые вопросы о разработке HRM
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-[#140c1c] rounded-lg shadow-lg overflow-hidden"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center px-6 py-4 text-left font-semibold text-lg hover:bg-[#27203e] transition"
              >
                <span>{faq.question}</span>
                <span>
                  {activeIndex === index ? (
                    <i className="fas fa-chevron-up text-purple-400"></i>
                  ) : (
                    <i className="fas fa-chevron-down text-purple-400"></i>
                  )}
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 text-gray-300">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordionHRM;
