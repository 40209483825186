import React, { useState } from "react";

const faqs = [
  {
    question: "Какова стоимость разработки стартапа с нуля?",
    answer:
      "Стоимость разработки стартапа зависит от сложности идеи, требуемого функционала и используемых технологий. Обычно цены начинаются от 300 тысяч рублей для MVP.",
  },
  {
    question: "Почему стоит выбрать индивидуальную разработку для стартапа?",
    answer:
      "Индивидуальная разработка позволяет создать уникальное решение, которое полностью соответствует вашей бизнес-идее. Это даёт вам конкурентное преимущество и гибкость в масштабировании.",
  },
  {
    question: "Сколько времени занимает разработка стартапа?",
    answer:
      "Разработка стартапа может занять от 2 до 6 месяцев в зависимости от сложности проекта, объёма работ и наличия готового технического задания.",
  },
  {
    question: "Какие технологии используются при создании стартапа?",
    answer:
      "Мы используем современные технологии, включая React, Node.js, Python, блокчейн, облачные решения и многое другое, чтобы обеспечить надежность и масштабируемость вашего стартапа.",
  },
  {
    question: "Какой уровень поддержки предоставляется после запуска стартапа?",
    answer:
      "Мы предлагаем техническую поддержку, мониторинг производительности, регулярные обновления и помощь в улучшении функционала вашего продукта.",
  },
  {
    question: "Как начать процесс разработки стартапа?",
    answer:
      "Начните с идеи! Свяжитесь с нами, чтобы обсудить ваши цели. Мы поможем вам провести анализ рынка, создать прототип и разработать MVP для тестирования вашей идеи.",
  },
];

const FAQAccordionStartup = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-purple-400 mb-6">
          Часто задаваемые вопросы о разработке стартапов
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-[#140c1c] rounded-lg shadow-lg overflow-hidden"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center px-6 py-4 text-left font-semibold text-lg hover:bg-[#27203e] transition"
              >
                <span>{faq.question}</span>
                <span>
                  {activeIndex === index ? (
                    <i className="fas fa-chevron-up text-purple-400"></i>
                  ) : (
                    <i className="fas fa-chevron-down text-purple-400"></i>
                  )}
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 text-gray-300">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordionStartup;
