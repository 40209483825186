import React, { useState } from "react";
import HRMFeatures from "./HRMFeatures";
import Portfolio from "./Portfolio";
import Stats from "./Stats";
import Navbar from "./Navbar";
import TechStack from "./TechStack";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import FAQAccordionHRM from "./FAQAccordionHRM";
import axios from "axios";
import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import IndexFeatures from "./IndexFeatures";
import FAQAccordionERP from "./FAQAccordionERP";
import FAQAccordionCRM from "./FAQAccordionCRM";
import FAQAccordionLogistics from "./FAQAccordionLogistics";
import FAQAccordionEcommerce from "./FAQAccordionEcommerce";
import FAQAccordionWeb3 from "./FAQAccordionWeb3";

import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css"; // подключаем стили
import AboutMe from "./AboutMe";
import QuizForm from "./QuizForm";

const IndexPage = () => {
  const notyf = new Notyf();

  const [formData, setFormData] = useState({
    phone: "",
  });

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  const handleChange = (value) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: value,
    }));
    setError(""); // Сбрасываем ошибку при изменении номера
  };

  const isPhoneNumberSuspicious = (phone) => {
    const phoneWithoutSpaces = phone.replace(/\s+/g, ""); // Убираем пробелы
    const regex = /(\d)\1{5,}/; // Проверка на подряд идущие одинаковые цифры (например: 777777, 999999)
    return regex.test(phoneWithoutSpaces);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.phone || !isValidPhoneNumber(formData.phone)) {
      setError("Пожалуйста, введите корректный номер телефона.");
      return; // Прерываем отправку формы
    }

    if (isPhoneNumberSuspicious(formData.phone)) {
      setError(
        "Номер телефона выглядит подозрительным. Пожалуйста, введите другой."
      );
      return; // Прерываем отправку формы
    }

    setLoading(true);

    try {
      // Отправка данных на сервер
      const response = await axios.post("/api/send", formData);

      // Успешное выполнение
      if (response.data.success) {
        notyf.success("Форма успешно отправлена!");
        setFormData({
          phone: "",
          telegram: "",
        });
      } else {
        notyf.error("Произошла ошибка при отправке формы.");
      }
    } catch (error) {
      console.error("Ошибка отправки формы:", error);
      notyf.error("Не удалось отправить форму. Попробуйте позже.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Navbar />
      <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
        <div className="container mt-24 mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-8">
          <div className="lg:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Комплексные IT-решения для бизнеса
            </h1>
            <h2 className="text-4xl sm:text-5xl font-bold text-purple-400 mb-6">
              ERP, CRM, WEB3
              <br />и многое другое
            </h2>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Мы разрабатываем индивидуальные IT-решения для оптимизации
              бизнес-процессов: от управления ресурсами (ERP) и автоматизации
              продаж (CRM) до аналитических платформ (BI) и e-commerce решений.
            </p>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Наша цель — предоставить вам инструменты, которые помогут
              увеличить эффективность, сократить издержки и достичь
              стратегических целей.
            </p>
            <a href="#projects" className="btn-outline hidden sm:inline-block">
              Узнать больше
            </a>
          </div>
          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <QuizForm />
          </div>

        </div>
      </div>
      <IndexFeatures />
      <Stats />
      <AboutMe />
      <Portfolio />
      <TechStack />
      <Testimonials />
      <FAQAccordionERP />
      <FAQAccordionCRM />
      <FAQAccordionHRM />
      <FAQAccordionLogistics />
      <FAQAccordionEcommerce />
      <FAQAccordionWeb3 />
      <ContactUs />
    </>
  );
};

export default IndexPage;
