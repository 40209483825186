import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import axios from "axios";
import { Notyf } from "notyf";
import "react-phone-number-input/style.css";
import "notyf/notyf.min.css";
import { useLocation } from "react-router-dom";
const QuizForm = () => {
  const notyf = new Notyf();

  const [step, setStep] = useState(0);
  const [answers, setAnswers] = useState({});
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const normalizedPathname = location.pathname.replace(/\/+$/, "");

  const quizQuestionsByRoute = {
    "/crm": [
      {
        question: "Какой у вас бюджет на CRM-систему?",
        options: [
          { text: "Меньше 50 тысяч рублей", value: "low_budget" },
          { text: "От 50 до 300 тысяч рублей", value: "medium_budget" },
          { text: "Больше 300 тысяч рублей", value: "high_budget" },
        ],
      },
      {
        question: "Как быстро вам нужно решение?",
        options: [
          { text: "Очень срочно (до 1 месяца)", value: "urgent" },
          { text: "Могу подождать (1-3 месяца)", value: "moderate" },
          { text: "Готов ждать более 3 месяцев", value: "flexible" },
        ],
      },
      {
        question: "Нужна ли вам полная кастомизация CRM?",
        options: [
          { text: "Да, это необходимо", value: "custom" },
          { text: "Нет, стандартных функций хватит", value: "standard" },
        ],
      },
      {
        question: "Какой объём данных вам нужно обрабатывать?",
        options: [
          { text: "Небольшой объём", value: "small_data" },
          { text: "Средний объём", value: "medium_data" },
          { text: "Большой объём", value: "large_data" },
        ],
      },
    ],
    "/ecommerce": [
      {
        question: "Какой у вас бюджет на разработку интернет-магазина?",
        options: [
          { text: "Меньше 100 тысяч рублей", value: "low_budget" },
          { text: "От 100 до 300 тысяч рублей", value: "medium_budget" },
          { text: "Больше 300 тысяч рублей", value: "high_budget" },
        ],
      },
      {
        question: "Насколько быстро вам нужно решение?",
        options: [
          { text: "Очень срочно (до 1 месяца)", value: "urgent" },
          { text: "Могу подождать (1-2 месяца)", value: "moderate" },
          { text: "Готов ждать дольше 2 месяцев", value: "flexible" },
        ],
      },
      {
        question: "Нужна ли вам полная кастомизация дизайна и функций?",
        options: [
          { text: "Да, это обязательно", value: "custom" },
          { text: "Нет, стандартного функционала хватит", value: "standard" },
        ],
      },
    ],
    "/erp": [
      {
        question: "Какой у вас бюджет на ERP-систему?",
        options: [
          { text: "Меньше 100 тысяч рублей", value: "low_budget" },
          { text: "От 100 до 500 тысяч рублей", value: "medium_budget" },
          { text: "Больше 500 тысяч рублей", value: "high_budget" },
        ],
      },
      {
        question: "Насколько быстро вам нужно решение?",
        options: [
          { text: "Очень срочно (до 1 месяца)", value: "urgent" },
          { text: "Могу подождать (1-3 месяца)", value: "moderate" },
          { text: "Готов ждать дольше 3 месяцев", value: "flexible" },
        ],
      },
      {
        question: "Насколько сложные у вас бизнес-процессы?",
        options: [
          { text: "Очень простые", value: "simple" },
          { text: "Средней сложности", value: "medium" },
          { text: "Очень сложные", value: "complex" },
        ],
      },
      {
        question: "Вам нужна полная кастомизация системы?",
        options: [
          { text: "Да, это обязательно", value: "custom" },
          { text: "Нет, стандартных функций хватит", value: "standard" },
        ],
      },
    ],
    "/hrm": [
      {
        question: "Какой у вас бюджет на HRM-систему?",
        options: [
          { text: "Меньше 100 тысяч рублей", value: "low_budget" },
          { text: "От 100 до 400 тысяч рублей", value: "medium_budget" },
          { text: "Больше 400 тысяч рублей", value: "high_budget" },
        ],
      },
      {
        question: "Насколько быстро вам нужно решение?",
        options: [
          { text: "Очень срочно (до 1 месяца)", value: "urgent" },
          { text: "Могу подождать (1-2 месяца)", value: "moderate" },
          { text: "Готов ждать дольше 2 месяцев", value: "flexible" },
        ],
      },
      {
        question: "Какие у вас задачи по управлению персоналом?",
        options: [
          { text: "Только базовые: учёт и зарплата", value: "basic_tasks" },
          { text: "Средний уровень: обучение и отчёты", value: "medium_tasks" },
          {
            text: "Сложные задачи: управление талантами",
            value: "complex_tasks",
          },
        ],
      },
      {
        question: "Вам нужна полная кастомизация системы?",
        options: [
          { text: "Да, это обязательно", value: "custom" },
          { text: "Нет, стандартных функций хватит", value: "standard" },
        ],
      },
    ],
    "/wms": [
      {
        question: "Какой у вас бюджет на логистическую систему?",
        options: [
          { text: "Меньше 100 тысяч рублей", value: "low_budget" },
          { text: "От 100 до 500 тысяч рублей", value: "medium_budget" },
          { text: "Больше 500 тысяч рублей", value: "high_budget" },
        ],
      },
      {
        question: "Насколько быстро вам нужно решение?",
        options: [
          { text: "Очень срочно (до 1 месяца)", value: "urgent" },
          { text: "Могу подождать (1-3 месяца)", value: "moderate" },
          { text: "Готов ждать дольше 3 месяцев", value: "flexible" },
        ],
      },
      {
        question: "Какую функциональность вы ожидаете?",
        options: [
          { text: "Базовые функции управления", value: "basic" },
          {
            text: "Расширенные функции, включая маршруты и аналитику",
            value: "advanced",
          },
          { text: "Максимальная кастомизация", value: "custom" },
        ],
      },
      {
        question: "Вам нужна полная кастомизация системы?",
        options: [
          { text: "Да, это обязательно", value: "custom" },
          { text: "Нет, стандартных функций хватит", value: "standard" },
        ],
      },
    ],
    "/startup": [
      {
        question: "Какой у вас бюджет на разработку стартапа?",
        options: [
          { text: "Меньше 200 тысяч рублей", value: "low_budget" },
          { text: "От 200 до 800 тысяч рублей", value: "medium_budget" },
          { text: "Больше 800 тысяч рублей", value: "high_budget" },
        ],
      },
      {
        question: "Как быстро вы хотите запустить стартап?",
        options: [
          { text: "Очень срочно (до 1 месяца)", value: "urgent" },
          { text: "Могу подождать 1-3 месяца", value: "moderate" },
          { text: "Готов ждать более 3 месяцев", value: "flexible" },
        ],
      },
      {
        question: "Насколько уникальна идея вашего стартапа?",
        options: [
          { text: "Очень простая, можно использовать шаблон", value: "simple" },
          { text: "Средней сложности, но требует доработок", value: "medium" },
          {
            text: "Совершенно уникальная, нужен полный кастом",
            value: "complex",
          },
        ],
      },
      {
        question: "Вы готовы к масштабированию стартапа?",
        options: [
          { text: "Нет, главное — протестировать идею", value: "mvp" },
          {
            text: "Да, планирую масштабировать с самого начала",
            value: "scalable",
          },
        ],
      },
    ],
    "/web3": [
      {
        question: "Какой у вас бюджет на Web3-решение?",
        options: [
          { text: "Меньше 500 тысяч рублей", value: "low_budget" },
          { text: "От 500 тысяч до 1 миллиона рублей", value: "medium_budget" },
          { text: "Больше 1 миллиона рублей", value: "high_budget" },
        ],
      },
      {
        question: "Насколько быстро вам нужно решение?",
        options: [
          { text: "Очень срочно (до 1 месяца)", value: "urgent" },
          { text: "Могу подождать (1-3 месяца)", value: "moderate" },
          { text: "Готов ждать дольше 3 месяцев", value: "flexible" },
        ],
      },
      {
        question: "Нужны ли вам уникальные функции?",
        options: [
          { text: "Да, нам нужны уникальные возможности", value: "custom" },
          { text: "Нет, достаточно стандартных функций", value: "standard" },
        ],
      },
      {
        question: "Какую степень контроля над данными вы хотите иметь?",
        options: [
          { text: "Полный контроль над данными", value: "full_control" },
          { text: "Достаточно доверять провайдеру", value: "trust_provider" },
        ],
      },
    ],
  };

  const questions = quizQuestionsByRoute[normalizedPathname] || [
    {
      question: "Какой тип IT-решения вы ищете?",
      options: [
        { text: "CRM-система", value: "crm" },
        { text: "ERP-система", value: "erp" },
        { text: "eCommerce", value: "ecommerce" },
        { text: "HRM-система", value: "hrm" },
      ],
    },
    {
      question: "Какой у вас бюджет на внедрение IT-решения?",
      options: [
        { text: "Меньше 100 тысяч рублей", value: "low_budget" },
        { text: "От 100 до 500 тысяч рублей", value: "medium_budget" },
        { text: "Больше 500 тысяч рублей", value: "high_budget" },
      ],
    },
    {
      question: "Как срочно вам нужно решение?",
      options: [
        { text: "Очень срочно (до 1 месяца)", value: "urgent" },
        { text: "В течение 1-3 месяцев", value: "moderate" },
        { text: "Не тороплюсь", value: "flexible" },
      ],
    },
  ];

  const handleOptionClick = (option) => {
    setAnswers((prev) => ({ ...prev, [step]: option }));
    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      setStep("form");
    }
  };

  const isPhoneNumberSuspicious = (phone) => {
    const phoneWithoutSpaces = phone.replace(/\s+/g, ""); // Убираем пробелы
    const regex = /(\d)\1{5,}/; // Проверка на подряд идущие одинаковые цифры (например: 777777, 999999)
    return regex.test(phoneWithoutSpaces);
  };

  const handlePhoneSubmit = async (e) => {
    e.preventDefault();

    if (!phone || !isValidPhoneNumber(phone)) {
      setError("Пожалуйста, введите корректный номер телефона.");
      return;
    }
    if (isPhoneNumberSuspicious(phone)) {
      setError(
        "Номер телефона выглядит подозрительным. Пожалуйста, введите другой."
      );
      return; // Прерываем отправку формы
    }
    setLoading(true);

    try {
      const dataToSend = {
        phone,
        answers,
      };

      // Отправка данных на сервер
      const response = await axios.post("/api/send", dataToSend);

      if (response.data.success) {
        notyf.success("Форма успешно отправлена!");
        // Сброс формы
        setStep(0);
        setAnswers({});
        setPhone("");
        setError("");
        ym(99037244, "reachGoal", "formDone");
      } else {
        notyf.error("Произошла ошибка при отправке формы.");
      }
    } catch (error) {
      console.error("Ошибка отправки формы:", error);
      notyf.error("Не удалось отправить форму. Попробуйте позже.");
    } finally {
      setLoading(false);
    }
  };
  const getRecommendation = () => {
    const answerValues = Object.values(answers); // Получаем массив значений из объекта answers

    if (location.pathname === "/crm") {
      if (
        answerValues.includes("custom") ||
        answerValues.includes("large_data")
      ) {
        return "Мы рекомендуем: Индивидуальную CRM-систему, полностью адаптированную под ваши бизнес-процессы.";
      }
      return "Мы рекомендуем: Готовую CRM-систему для быстрого старта.";
    }

    if (location.pathname === "/ecommerce") {
      if (answerValues.includes("custom")) {
        return "Мы рекомендуем: Интернет-магазин с полной кастомизацией, чтобы выделиться среди конкурентов.";
      }
      return "Мы рекомендуем: Готовое решение для интернет-магазина для быстрого запуска.";
    }

    if (location.pathname === "/erp") {
      if (answerValues.includes("complex") || answerValues.includes("custom")) {
        return "Мы рекомендуем: ERP-систему с глубоким уровнем кастомизации для автоматизации сложных бизнес-процессов.";
      }
      return "Мы рекомендуем: Готовое ERP-решение для оптимизации стандартных процессов.";
    }

    if (location.pathname === "/hrm") {
      if (
        answerValues.includes("complex_tasks") ||
        answerValues.includes("custom")
      ) {
        return "Мы рекомендуем: Индивидуальное HRM-решение для управления сложными процессами, включая оценку и обучение.";
      }
      return "Мы рекомендуем: Готовое HRM-решение для базового управления персоналом.";
    }

    if (location.pathname === "/wms") {
      if (
        answerValues.includes("advanced") ||
        answerValues.includes("custom")
      ) {
        return "Мы рекомендуем: WMS-систему с расширенной аналитикой и полным уровнем кастомизации.";
      }
      return "Мы рекомендуем: Базовое WMS-решение для управления складскими процессами.";
    }

    if (location.pathname === "/startup") {
      if (
        answerValues.includes("complex") ||
        answerValues.includes("scalable")
      ) {
        return "Мы рекомендуем: Полностью кастомизированное решение для вашего стартапа с возможностью масштабирования.";
      }
      return "Мы рекомендуем: MVP (минимально жизнеспособный продукт) для тестирования вашей идеи.";
    }

    if (location.pathname === "/web3") {
      if (
        answerValues.includes("custom") ||
        answerValues.includes("full_control")
      ) {
        return "Мы рекомендуем: Уникальное Web3-решение с полным контролем над данными.";
      }
      return "Мы рекомендуем: Стандартное Web3-решение для быстрого старта.";
    }

    return "Мы готовы предложить вам оптимальное решение на основе ваших потребностей!";
  };

  return (
    <div className="form-card w-full max-w-md bg-[#1c1a35] p-6 rounded-lg shadow-md">
      {step !== "form" ? (
        <>
          {/* Добавленный текст перед вопросами */}
          <p className="text-lg text-gray-300 mb-6">
            Ответьте на простые вопросы и узнайте, какое решение вам подходит:
            готовое или индивидуальное.
          </p>

          <h3 className="text-2xl font-bold text-purple-400 mb-4">
            Вопрос {step + 1} из {questions.length}
          </h3>
          <p className="text-lg text-gray-300 mb-6">
            {questions[step]?.question}
          </p>
          <div className="grid gap-4">
            {questions[step]?.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleOptionClick(option.value)}
                className="bg-[#844df5] hover:bg-[#8757ea] text-white py-3 px-6 rounded-lg shadow transition"
              >
                {option.text}
              </button>
            ))}
          </div>
        </>
      ) : (
        <>
          <h3 className="text-2xl font-bold text-purple-400 mb-4">Готово!</h3>
          <p className="text-md text-gray-300 mb-6">{getRecommendation()}</p>
          <p className="text-md text-gray-300 mb-6">
            Вы можете связаться с нами, и мы поможем вам выбрать оптимальное
            решение для вашего бизнеса. Наша команда готова предложить
            индивидуальные условия и ответить на все ваши вопросы.
          </p>
          <form onSubmit={handlePhoneSubmit}>
            <div className="mb-4">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-300 mb-2"
              >
                Номер телефона
              </label>
              <PhoneInput
                international
                defaultCountry="RU"
                value={phone}
                onChange={setPhone}
                name="phone"
                className="phone-input w-full px-4 py-3 rounded-lg bg-[#1c1a35] text-gray-200 border border-gray-600 focus:outline-none focus:ring-2 focus:ring-purple-500"
                required
              />
              {error && <p className="text-red-500 mt-2">{error}</p>}
            </div>
            <button
              type="submit"
              className="btn-outline w-full bg-purple-600 text-white py-3 px-6 rounded-lg hover:bg-purple-500 transition"
              disabled={loading}
            >
              {loading ? "Отправка..." : "Отправить"}
            </button>
          </form>
        </>
      )}
    </div>
  );
};

export default QuizForm;
