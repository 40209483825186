import React, { useState } from "react";
import Portfolio from "./Portfolio";
import Stats from "./Stats";
import Navbar from "./Navbar";
import TechStack from "./TechStack";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import EcommerceFeatures from "./EcommerceFeatures";
import FAQAccordionEcommerce from "./FAQAccordionEcommerce";
import "react-phone-number-input/style.css"; // подключаем стили
import AboutMe from "./AboutMe";
import QuizForm from "./QuizForm";
import ComparisonComponent from "./ComparisonComponent";

const ECommercePage = () => {

  return (
    <>
      <Navbar />
      {/* Блок с текстом и формой */}
      <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
        <div className="container mt-24 mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-8">
          {/* Левая колонка с текстом */}
          <div className="lg:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Решения для электронной коммерции
            </h1>
            <h2 className="text-4xl sm:text-5xl font-bold text-purple-400 mb-6">
              Интернет-магазины <br /> и маркетплейсы на заказ
            </h2>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Мы разрабатываем уникальные решения для электронной коммерции,
              помогая бизнесу продавать онлайн: интернет-магазины, маркетплейсы
              и платформы для бронирования.
            </p>
            <ul className="list-disc list-inside text-sm sm:text-base mb-6 leading-relaxed font-light">
              <li>Интеграция с платежными системами и службами доставки.</li>
              <li>Создание удобного интерфейса для покупателей и продавцов.</li>
              <li>Автоматизация процессов управления заказами и складами.</li>
            </ul>
            <a href="#projects" className="btn-outline hidden sm:inline-block">
              Узнать больше
            </a>
          </div>
          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <QuizForm />
          </div>

        </div>
      </div>
      <EcommerceFeatures />
      <ComparisonComponent route="/ecommerce" />
      <Stats />
      <Portfolio />
      <AboutMe />
      <TechStack />
      <Testimonials />
      <FAQAccordionEcommerce />
      <ContactUs />
    </>
  );
};

export default ECommercePage;
