import React from "react";
import PropTypes from "prop-types";

const dataByRoute = {
  "/": {
    title: "Добро пожаловать на главную страницу",
    comparisonData: [
      {
        title: "Общее решение",
        points: [
          "Краткий обзор всех наших решений.",
          "Узнайте больше о наших продуктах.",
        ],
      },
    ],
  },
  "/erp": {
    title: "Сравнение ERP-систем",
    comparisonData: [
      {
        title: "Готовая ERP-система",
        points: [
          "Подписка от 20 тысяч рублей в месяц.",
          "Быстрая установка за 1–2 недели.",
          "Ограниченная кастомизация стандартных функций.",
          "Поддержка типичных бизнес-процессов.",
          "Сложности с адаптацией к уникальным требованиям.",
          "Сложности интеграции с внутренними системами.",
        ],
      },
      {
        title: "Индивидуальная ERP-система",
        points: [
          "Полная адаптация под уникальные процессы бизнеса.",
          "Возможности глубокой аналитики и кастомизации.",
          "Неограниченные модули и расширения.",
          "Интеграция с любыми внутренними и внешними системами.",
          "Срок разработки — от 3 до 6 месяцев.",
          "Индивидуальный подход, минимизация рисков.",
        ],
      },
    ],
  },
  "/crm": {
    title: "Сравнение CRM-систем",
    comparisonData: [
      {
        title: "Готовая CRM-система",
        points: [
          "Низкая стоимость — от 10 тысяч рублей в месяц.",
          "Быстрое внедрение (до 2 недель).",
          "Подходит для малого бизнеса и стандартных продаж.",
          "Может быть сложным для сложных бизнес-циклов.",
          "Отсутствие глубокого анализа или кастомизации.",
          "Зависимость от ограниченного набора функций.",
        ],
      },
      {
        title: "Индивидуальная CRM-система",
        points: [
          "Разработка под уникальные бизнес-сценарии.",
          "Интеграция с внутренними системами и ERP.",
          "Поддержка сложных воронок продаж и аналитики.",
          "Высокая стоимость, но максимальная гибкость.",
          "Масштабируемость и адаптация под рост бизнеса.",
          "Контроль данных в рамках вашей инфраструктуры.",
        ],
      },
    ],
  },
  "/wms": {
    title: "Сравнение WMS/TMS-систем",
    comparisonData: [
      {
        title: "Готовая логистическая система",
        points: [
          "Подписка от 15 тысяч рублей.",
          "Быстрое внедрение (1-2 недели).",
          "Ограниченный функционал.",
          "Сложности масштабирования под ваши потребности.",
          "Данные хранятся у поставщика системы.",
          "Ограниченные возможности интеграции.",
        ],
      },
      {
        title: "Индивидуальная логистическая система",
        points: [
          "Кастомизация под специфику бизнеса.",
          "Неограниченное количество пользователей и складов.",
          "Хранение данных на вашем сервере.",
          "Возможности интеграции с любыми системами.",
          "Высокая производительность.",
          "Поддержка уникальных функций.",
        ],
      },
    ],
  },
  "/hrm": {
    title: "Сравнение HRM-систем",
    comparisonData: [
      {
        title: "Готовая HRM-система",
        points: [
          "Подходит для малого и среднего бизнеса.",
          "Быстрая настройка стандартных модулей.",
          "Может быть ограничен функционал.",
          "Стоимость от 10 тысяч рублей в месяц.",
          "Данные хранятся у провайдера.",
          "Ограничения в интеграциях.",
        ],
      },
      {
        title: "Индивидуальная HRM-система",
        points: [
          "Кастомизация под уникальные процессы.",
          "Поддержка автоматизации оценки сотрудников.",
          "Гибкость в масштабировании под ваш бизнес.",
          "Срок разработки — от 2 месяцев.",
          "Хранение данных на вашем сервере.",
          "Максимальная безопасность и контроль.",
        ],
      },
    ],
  },
  "/btc": {
    title: "Сравнение Web3-решений",
    comparisonData: [
      {
        title: "Готовое Web3-решение",
        points: [
          "Быстрое внедрение и настройка.",
          "Ограниченная функциональность.",
          "Меньший контроль над данными.",
          "Доступно по подписке.",
          "Зависимость от провайдера.",
          "Сложности интеграции с другими системами.",
        ],
      },
      {
        title: "Индивидуальное Web3-решение",
        points: [
          "Максимальная гибкость и кастомизация.",
          "Полный контроль над данными.",
          "Возможности для интеграции с различными блокчейнами.",
          "Длительный срок разработки.",
          "Высокая стоимость, но уникальная реализация.",
          "Поддержка любых сетей и протоколов.",
        ],
      },
    ],
  },
  "/ecommerce": {
    title: "Сравнение eCommerce решений",
    comparisonData: [
      {
        title: "Готовая платформа eCommerce",
        points: [
          "Низкая стоимость — от 5 тысяч рублей в месяц.",
          "Быстрое внедрение.",
          "Ограниченные возможности кастомизации.",
          "Подходит для простых магазинов.",
          "Меньший контроль над платформой.",
          "Ограниченная аналитика.",
        ],
      },
      {
        title: "Индивидуальное eCommerce решение",
        points: [
          "Поддержка уникального функционала.",
          "Масштабируемость и высокая производительность.",
          "Интеграция с любыми сервисами.",
          "Высокая стоимость разработки.",
          "Полный контроль над данными.",
          "Разработка под ваши потребности.",
        ],
      },
    ],
  },
  "/startup": {
    title: "Сравнение решений для стартапов",
    comparisonData: [
      {
        title: "Готовое решение для стартапа",
        points: [
          "Низкая стоимость, быстрая реализация.",
          "Подходит для тестирования MVP.",
          "Может быть сложно масштабировать.",
          "Стандартный функционал.",
          "Подходит для начинающих стартапов.",
          "Ограниченная кастомизация.",
        ],
      },
      {
        title: "Индивидуальная разработка для стартапа",
        points: [
          "Кастомизация под уникальную идею.",
          "Масштабируемое решение.",
          "Высокая стоимость разработки.",
          "Подходит для уникальных и сложных проектов.",
          "Гибкая архитектура для будущего роста.",
          "Полный контроль над проектом.",
        ],
      },
    ],
  },
};

const ComparisonComponent = ({ route }) => {
  const routeData = dataByRoute[route];

  if (!routeData) {
    return <div>Данные для маршрута не найдены.</div>;
  }

  const { title, comparisonData } = routeData;

  return (
    <div className="bg-[#0f0715] text-white py-16 px-4">
      <h2 className="text-3xl text-center font-bold mb-6">{title}</h2>

      <div className="container mx-auto max-w-7xl grid grid-cols-1 md:grid-cols-2 gap-8">
        {comparisonData.map((item, index) => (
          <div key={index} className="bg-[#140c1c] p-8 rounded-lg shadow-lg">
            <h3 className="text-xl font-semibold text-purple-400 mb-4">
              {item.title}
            </h3>
            <ul className="list-disc list-inside space-y-2 text-gray-300">
              {item.points.map((point, idx) => (
                <li key={idx} className="hover:text-white transition">
                  {point}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};

ComparisonComponent.propTypes = {
  route: PropTypes.string.isRequired, // Маршрут передается как строка
};

export default ComparisonComponent;
