import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

const testimonials = [
  {
    name: "Ivan Meniailov",
    company: "Randevu",
    shortText: "Про таких людей как Дима вообще нельзя писать отзывы...",
    text: `
      Про таких людей как Дима вообще нельзя писать отзывы. Мне как заказчику надо бы просто молчать и делать так, чтобы он работал только со мной. Но обещал - пишу.
      
      Дима - это тот человек, которого ищет каждый заказчик. Чтобы просто дал ТЗ, дал деньги. Потом через какое-то время все готово. Да, это действительно так. Ни вопросов, ни отмазок, ни переносов сроков. Просто все делается быстро. Нет, очень быстро. Скорость написания кода иногда просто пугает. Так быстро без потери качества - мое почтение.
      
      По ценам. В начале сотрудничества казалось дороговато. Но сейчас я полностью согласен с его ценами. Он сэкономил такое огромное количество времени и нервов - это того стоит.
      
      И да, сделал он несколько больше, чем мы договаривались. Это приятно.
      
      Хорошо взаимодействует с командой. Спорит аргументировано (на пару листов А4 будут аргументы, почему он выбрал это решение и почему другие не подходят), так же может привлечь других специалистов, если у вас их нет (верстальщика например).
      
      Сотрудничеством доволен как слон. Пишу второе ТЗ и приду с новым заказом в ближайшую неделю.
      
      Дима, рад знакомству, спасибо за сотрудничество!
    `,
    avatar: "https://habrastorage.org/getpro/freelansim/users/avatars/109/1091/1091721/r100_7f15bf0a2fa624ebc09ac9a82ac54fb6.jpeg",
  },
  {
    name: "Арман",
    company: "Тронбосс",
    shortText: "евГений Понасенков от мира разработки...",
    text: `
      евГений Понасенков от мира разработки. Если вы хоть раз пытались что-то заказывать на фрилансе, наверное, понимаете классические боли заказчика: плохой код, после которого ни один адекватный человек не готов поддерживать проект; ложь в опыте — уже на всех обучающих курсах по разработке людей учат с самого старта говорить, что умеют все и могут выполнить любую задачу; команды реселлеров, перепродающие заказы, где до разработчика доходит, дай бог, 20% от суммы заказа. 

      А ещё пропадание со связи и выгорания. Тут отдали 25% предоплаты, там — 50%. В итоге весь бюджет, выделенный на проект, раздали каким-то проходимцам и не получили даже 10% от того, что планировали.

      Если у вас есть ТЗ, чёткая задача и понимание целевой аудитории вашего нового проекта, то Дмитрий это сделает и сделает очень хорошо. Да, на первый взгляд это выйдет дороже, чем вы рассчитывали. Но рассчитать это, в принципе, достаточно сложно изначально даже вместе с продакт-менеджером. 

      Плюс вы получите продукт, способный покорять мировые топы. А если вам этого не требуется, то просто проект, который можно положить на полку и осознавать гениальность маэстро.

      И всё это без вот этих вот коллов каждые полчаса с вопросами "а как мне сделать то или это". В общем, песня. Крайне рекомендую данного человека сделать вам какую-то задачу.
    `,
    avatar: "https://freelance.habr.com/assets/default/users/avatar_r100-510ec240a9384f321c7075c46962f4fad15dd58cd22cd41d7f1ca9b2e1732c00.png",
  },
  {
    name: "Илья Панов",
    company: "Uppora",
    shortText: "Сотрудничество на высшем уровне...",
    text: `
      Дмитрий создал идеальную платформу для нашего бизнеса. ERP-система не только полностью автоматизировала все бизнес-процессы, но и помогла оптимизировать ресурсы. Всё было выполнено вовремя, без единой задержки. Техническая поддержка работает 24/7, а качество кода на высшем уровне.
      
      Особенно хотелось бы отметить подход к работе: Дмитрий тщательно выслушал все наши пожелания и предложил множество идей, которые мы не учли. Система настолько гибкая, что даже после запуска нам удалось добавить новые функции без лишних затрат.
      
      Если вы ищете профессионала, который знает своё дело, то Дмитрий — это лучший выбор. Спасибо за сотрудничество!
    `,
    avatar: "/img/testimonials/user/photo_2021-03-14_20-53-54.jpg",
  },
  {
    name: "Александр",
    company: "Газпром АГНКС",
    shortText: "Реально быстро и хорошо сделал...",
    text: `
      Реально быстро и хорошо сделал, мне очень приятно работать в таких условиях.
    `,
    avatar: "/img/testimonials/user/photo_2021-12-11_17-41-32.jpg",
  },
  {
    name: "Сергей Марковский",
    company: "Ярдемче",
    shortText: "Отличный специалист...",
    text: `
      Отличный специалист, в сложных моментах предлагает хорошие альтернативные решения, сроки соблюдаются, заказ выполнен в полном объеме даже раньше срока. Заботливо помогал верстальщице разобраться с её косяками.
    `,
    avatar: "https://habrastorage.org/getpro/freelansim/users/avatars/101/1013/1013583/r100_482a5f5b562c7a20cafae41834a38379.png",
  },
];

const Testimonials = () => {
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (index) => {
    setExpanded((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  return (
    <section id="reviews" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto">
        {/* Заголовок */}
        <div className="text-left mb-8">
          <h2 className="text-4xl font-bold mb-4 text-purple-400">
            Отзывы наших клиентов
          </h2>
          <p className="text-gray-400 text-lg">
            Мы помогаем бизнесу расти и развиваться, предоставляя уникальные
            цифровые решения.
          </p>
        </div>
        {/* Слайдер */}
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          breakpoints={{
            768: { slidesPerView: 3 },
          }}
          className="testimonial-swiper"
        >
          {testimonials.map((testimonial, index) => (
            <SwiperSlide key={index}>
              <div className="bg-[#140c1c] rounded-lg p-6 shadow-lg h-full flex flex-col">
                {/* Аватар */}
                <div className="bg-[#140c1c] p-4 rounded-t-lg">
                  <img
                    src={testimonial.avatar}
                    alt={testimonial.name}
                    className="w-16 h-16 rounded-full mx-auto"
                  />
                </div>
                {/* Текст отзыва */}
                <div className="p-4 flex-1">
                  <p className="text-gray-300 mb-4">
                    <i className="fas fa-quote-left text-purple-400 mr-2"></i>
                    {expanded[index] ? testimonial.text : testimonial.shortText}
                  </p>
                  <button
                    onClick={() => toggleExpand(index)}
                    className="text-purple-400 hover:text-purple-600 mt-4 text-sm"
                  >
                    {expanded[index] ? "Скрыть" : "Показать еще"}
                  </button>
                  <p className="text-purple-400 text-sm font-semibold mt-4">
                    {testimonial.name}
                  </p>
                  <p className="text-gray-400 text-xs">{testimonial.company}</p>
                </div>

              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {/* Пагинация */}
        <style jsx>{`
          .swiper-pagination-bullet {
            background: #ffffff;
            opacity: 0.5;
          }
          .swiper-pagination-bullet-active {
            background: #9f7aea;
            opacity: 1;
          }
        `}</style>
      </div>
    </section>
  );
};

export default Testimonials;
