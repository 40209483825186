import React, { useState } from "react";

const ServiceConfigurator = () => {
  const [businessSize, setBusinessSize] = useState("");
  const [backendNeeded, setBackendNeeded] = useState(false);
  const [frontendNeeded, setFrontendNeeded] = useState(false);
  const [designNeeded, setDesignNeeded] = useState(false);
  const [deployNeeded, setDeployNeeded] = useState(false);
  const [serviceType, setServiceType] = useState("");
  const [integrationType, setIntegrationType] = useState([]);
  const [price, setPrice] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleBusinessSizeChange = (e) => {
    setBusinessSize(e.target.value);
    calculatePrice(
      serviceType,
      e.target.value,
      backendNeeded,
      frontendNeeded,
      designNeeded,
      deployNeeded,
      integrationType
    );
  };

  const handleServiceChange = (e) => {
    setServiceType(e.target.value);
    calculatePrice(
      e.target.value,
      businessSize,
      backendNeeded,
      frontendNeeded,
      designNeeded,
      deployNeeded,
      integrationType
    );
  };

  const handleIntegrationChange = (e) => {
    const updatedIntegrations = [...integrationType];
    const value = e.target.value;
    if (updatedIntegrations.includes(value)) {
      updatedIntegrations.splice(updatedIntegrations.indexOf(value), 1);
    } else {
      updatedIntegrations.push(value);
    }
    setIntegrationType(updatedIntegrations);
    calculatePrice(
      serviceType,
      businessSize,
      backendNeeded,
      frontendNeeded,
      designNeeded,
      deployNeeded,
      updatedIntegrations
    );
  };

  const handleAdditionalServiceChange = (service) => {
    let newBackendNeeded = backendNeeded;
    let newFrontendNeeded = frontendNeeded;
    let newDesignNeeded = designNeeded;
    let newDeployNeeded = deployNeeded;

    // Меняем состояние выбранной услуги
    if (service === "backend") newBackendNeeded = !backendNeeded;
    if (service === "frontend") newFrontendNeeded = !frontendNeeded;
    if (service === "design") newDesignNeeded = !designNeeded;
    if (service === "deploy") newDeployNeeded = !deployNeeded;

    // Сразу вызываем функцию расчета с актуальными значениями
    calculatePrice(
      serviceType,
      businessSize,
      newBackendNeeded,
      newFrontendNeeded,
      newDesignNeeded,
      newDeployNeeded,
      integrationType
    );

    // Обновляем состояние с новыми значениями
    setBackendNeeded(newBackendNeeded);
    setFrontendNeeded(newFrontendNeeded);
    setDesignNeeded(newDesignNeeded);
    setDeployNeeded(newDeployNeeded);
  };

  const calculatePrice = (
    serviceType,
    businessSize,
    backendNeeded,
    frontendNeeded,
    designNeeded,
    deployNeeded,
    integrationType
  ) => {
    // Определяем срок выполнения проекта в зависимости от размера бизнеса
    let baseDuration = 0;
    if (businessSize === "small") {
      baseDuration = 30; // 30 дней для малого бизнеса
    } else if (businessSize === "medium") {
      baseDuration = 60; // 60 дней для среднего бизнеса
    } else if (businessSize === "large") {
      baseDuration = 90; // 90 дней для крупного бизнеса
    }

    // Стоимость работы разработчиков в месяц для каждого типа услуги
    const developerCosts = {
      ERP: {
        backend: 300000,
        frontend: 300000,
        design: 100000,
        deploy: 100000,
      },
      CRM: {
        backend: 300000,
        frontend: 300000,
        design: 100000,
        deploy: 100000,
      },
      Ecommerce: {
        backend: 150000,
        frontend: 150000,
        design: 50000,
        deploy: 50000,
      },
      WMS: {
        backend: 400000,
        frontend: 300000,
        design: 100000,
        deploy: 100000,
      },
      Web3: {
        backend: 400000,
        frontend: 300000,
        design: 100000,
        deploy: 100000,
      },
      Startup: {
        backend: 200000,
        frontend: 100000,
        design: 50000,
        deploy: 50000,
      },
    };

    // Проверка на существование ключа в developerCosts
    if (!developerCosts[serviceType]) {
      console.error("Invalid service type selected:", serviceType);
      return; // Прекращаем выполнение, если тип услуги не существует
    }

    // Стоимость разработки (считаем только на основе выбранных разработчиков)
    let totalPrice = 0;

    // Учитываем выбранные услуги и суммируем стоимость за месяц
    if (backendNeeded) totalPrice += developerCosts[serviceType].backend;
    if (frontendNeeded) totalPrice += developerCosts[serviceType].frontend;
    if (deployNeeded) totalPrice += developerCosts[serviceType].deploy;

    // Умножаем на количество месяцев в сроке проекта (1 месяц = 30 дней)
    const projectDurationInMonths = baseDuration / 30; // делим количество дней на 30, чтобы получить количество месяцев
    totalPrice *= projectDurationInMonths; // Умножаем стоимость на количество месяцев

    // Если выбран дизайн, добавляем фиксированную стоимость дизайнера (не зависит от месяца)
    if (designNeeded) {
      totalPrice += developerCosts[serviceType].design; // Добавляем фиксированную цену дизайна
    }

    // Стоимость интеграций
    const integrationCosts = {
      payment: 250000, // Стоимость интеграции с платежной системой
      analytics: 300000, // Стоимость интеграции с аналитикой
      crm: 160000, // Стоимость интеграции с CRM
    };

    // Учитываем выбранные интеграции и добавляем к стоимости
    integrationType.forEach((integration) => {
      if (integrationCosts[integration]) {
        totalPrice += integrationCosts[integration]; // Добавляем стоимость интеграции
      }
    });

    // Итоговые значения
    setPrice(totalPrice); // Пересчитываем итоговую цену
    setDuration(baseDuration); // Срок без учета интеграций, потому что они только увеличивают цену, а не срок
  };

  return (
    <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
      <div className="container mx-auto max-w-7xl text-white p-6 sm:p-8 lg:p-10 bg-[#140c1c] rounded-lg">
        <h2 className="text-3xl font-bold mb-6 text-center sm:text-left">
          Калькулятор услуги
        </h2>

        {/* Шаг 1: Выбор размера бизнеса */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-3">
            Выберите размер бизнеса
          </h3>
          <div className="flex flex-wrap sm:space-x-6 lg:space-x-4 space-y-4 sm:space-y-0">
            {["small", "medium", "large"].map((size) => (
              <button
                key={size}
                onClick={handleBusinessSizeChange}
                value={size}
                className={`p-3 sm:p-4 lg:p-5 ${
                  businessSize === size ? "bg-[#5f1ce8]" : "bg-[#844df5]"
                } text-white rounded-lg w-full sm:w-auto`}
              >
                {size === "small"
                  ? "Малый"
                  : size === "medium"
                  ? "Средний"
                  : "Крупный"}
              </button>
            ))}
          </div>
        </div>

        {/* Шаг 2: Выбор дополнительных услуг */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-3">Услуги</h3>
          <div className="flex flex-wrap sm:space-x-6 lg:space-x-4 space-y-4 sm:space-y-0">
            {["backend", "frontend", "design", "deploy"].map((service) => (
              <button
                key={service}
                onClick={() => handleAdditionalServiceChange(service)}
                className={`p-3 sm:p-4 lg:p-5 ${
                  service === "backend" && backendNeeded
                    ? "bg-[#5f1ce8]"
                    : service === "frontend" && frontendNeeded
                    ? "bg-[#5f1ce8]"
                    : service === "design" && designNeeded
                    ? "bg-[#5f1ce8]"
                    : service === "deploy" && deployNeeded
                    ? "bg-[#5f1ce8]"
                    : businessSize
                    ? "bg-[#844df5]"
                    : "bg-[#1c1a35]"
                } text-white rounded-lg w-full sm:w-auto`}
                disabled={!businessSize}
              >
                {service === "backend"
                  ? "Нужен ли бэкэнд?"
                  : service === "frontend"
                  ? "Нужен ли фронтэнд?"
                  : service === "design"
                  ? "Нужен ли дизайн?"
                  : "Нужен ли деплой?"}
              </button>
            ))}
          </div>
        </div>

        {/* Шаг 3: Выбор типа услуги */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-3">Выберите тип услуги</h3>
          <div className="flex flex-wrap sm:space-x-6 lg:space-x-4 space-y-4 sm:space-y-0">
            {[
              "ERP",
              "CRM",
              "Ecommerce",
              "HRM",
              "Web3",
              "WMS/TMS",
              "Startup",
            ].map((type) => (
              <button
                key={type}
                onClick={handleServiceChange}
                value={type}
                className={`p-3 sm:p-4 lg:p-5 ${
                  serviceType === type
                    ? "bg-[#5f1ce8]"
                    : businessSize
                    ? "bg-[#844df5]"
                    : "bg-[#1c1a35]"
                } text-white rounded-lg w-full sm:w-auto`}
                disabled={!businessSize}
              >
                {type}
              </button>
            ))}
          </div>
        </div>

        {/* Шаг 4: Выбор интеграций */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold mb-3">Выберите интеграции</h3>
          <div className="flex flex-wrap sm:space-x-6 lg:space-x-4 space-y-4 sm:space-y-0">
            {["payment", "analytics", "crm"].map((integration) => (
              <button
                key={integration}
                onClick={handleIntegrationChange}
                value={integration}
                className={`p-3 sm:p-4 lg:p-5 ${
                  integrationType.includes(integration)
                    ? "bg-[#5f1ce8]"
                    : serviceType && businessSize
                    ? "bg-[#844df5]"
                    : "bg-[#1c1a35]"
                } text-white rounded-lg w-full sm:w-auto`}
                disabled={!serviceType}
              >
                {integration === "payment"
                  ? "Платежные системы"
                  : integration === "analytics"
                  ? "Системы аналитики"
                  : "Интеграция с CRM"}
              </button>
            ))}
          </div>
        </div>

        {/* Итоговая стоимость */}
        <div className="mb-4">
          <h3 className="text-xl font-semibold">Предварительная стоимость</h3>
          <p>Цена: {price.toLocaleString("ru-RU")} рублей</p>
          <p>Срок: {duration} дней</p>
          <p className="mt-4 text-sm text-gray-400">
            Это всего лишь предварительная стоимость, рассчитанная на основе
            очень малого количества информации.
            <br></br>Итоговая стоимость может отличаться как в меньшую, так и в большую сторону.
            <br></br>Для получения точной стоимости и сроков, пожалуйста,
            свяжитесь с нами.
          </p>
        </div>
      </div>
    </div>
  );
};

export default ServiceConfigurator;
