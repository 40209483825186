import React, { useState } from "react";
import Web3Features from "./Web3Features";
import Portfolio from "./Portfolio";
import TechStack from "./TechStack";
import Testimonials from "./Testimonials";
import FAQAccordionWeb3 from "./FAQAccordionWeb3";
import ContactUs from "./ContactUs";
import Stats from "./Stats";
import Navbar from "./Navbar";
import AboutMe from "./AboutMe";
import QuizForm from "./QuizForm";
import ComparisonComponent from "./ComparisonComponent";

const Web3Page = () => {
  return (
    <>
    <Navbar />
      {/* Блок с текстом и формой */}
      <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
        <div className="container mt-24 mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-8">
          {/* Левая колонка с текстом */}
          <div className="lg:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Технологии нового поколения
            </h1>
            <h2 className="text-4xl sm:text-5xl font-bold text-purple-400 mb-6">
              Web3 решения<br />
              для вашей компании
            </h2>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Мы создаём передовые Web3-платформы, основанные на блокчейне,
              смарт-контрактах и децентрализованных сетях. Решения для
              следующего поколения интернет-технологий.
            </p>
            <ul className="list-disc list-inside text-sm sm:text-base mb-6 leading-relaxed font-light">
              <li>Создание смарт-контрактов и токенов.</li>
              <li>Разработка децентрализованных приложений (dApps).</li>
              <li>Интеграция блокчейн-сетей и Web3 API.</li>
            </ul>
            {/* Кнопка (скрыта на мобильных устройствах) */}
            <a href="#projects" className="btn-outline hidden sm:inline-block">
              Узнать больше
            </a>
          </div>

          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <QuizForm />
          </div>
        </div>
      </div>
      <Web3Features />
      <ComparisonComponent route="/btc" />
      <Stats />

      <Portfolio />
      <AboutMe />
      <TechStack />
      <Testimonials />
      <FAQAccordionWeb3 />
      <ContactUs />
    </>
  );
};

export default Web3Page;
