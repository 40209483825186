import React, { useState } from "react";

const faqs = [
  {
    question: "Какова цена услуги по разработке WMS/TMS системы?",
    answer:
      "Цена разработки WMS/TMS системы начинается от 600 тысяч рублей. Итоговая стоимость зависит от сложности, интеграций и индивидуальных особенностей вашего бизнеса.",
  },
  {
    question: "Какие преимущества дает разработка логистической системы с нуля?",
    answer:
      "Разработка логистической системы с нуля позволяет учитывать специфику вашего бизнеса, обеспечивая полную интеграцию с текущими процессами, гибкость масштабирования и поддержку уникальных функций.",
  },
  {
    question: "Каковы сроки разработки WMS/TMS системы?",
    answer:
      "Средний срок разработки WMS/TMS системы составляет от 3 до 7 месяцев. Точные сроки зависят от объема функционала, интеграций и требований вашего проекта.",
  },
  {
    question: "Какие интеграции можно реализовать в логистической системе?",
    answer:
      "Логистическая система может быть интегрирована с ERP, бухгалтерией, системами управления складами, GPS-трекерами, маршрутными системами, аналитическими платформами и платежными шлюзами.",
  },
  {
    question: "Какой уровень поддержки предоставляется после внедрения системы?",
    answer:
      "Мы предоставляем техническую поддержку 24/7, регулярные обновления, а также помощь в оптимизации и развитии системы после её внедрения.",
  },
  {
    question: "Как начать процесс создания логистической системы?",
    answer:
      "Свяжитесь с нами для обсуждения ваших задач и целей. Мы проведем аудит текущих процессов, подготовим техническое задание и приступим к созданию уникальной логистической системы.",
  },
];

const FAQAccordionLogistics = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-purple-400 mb-6">
          Часто задаваемые вопросы о логистических системах
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-[#140c1c] rounded-lg shadow-lg overflow-hidden"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center px-6 py-4 text-left font-semibold text-lg hover:bg-[#27203e] transition"
              >
                <span>{faq.question}</span>
                <span>
                  {activeIndex === index ? (
                    <i className="fas fa-chevron-up text-purple-400"></i>
                  ) : (
                    <i className="fas fa-chevron-down text-purple-400"></i>
                  )}
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 text-gray-300">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordionLogistics;
