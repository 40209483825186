import React, { useState } from "react";
import Portfolio from "./Portfolio";
import Stats from "./Stats";
import Navbar from "./Navbar";
import TechStack from "./TechStack";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import StartupFeatures from "./StartupFeatures";
import FAQAccordionStartup from "./FAQAccordionStartup";

import AboutMe from "./AboutMe";
import QuizForm from "./QuizForm";
import ComparisonComponent from "./ComparisonComponent";

const StartupPage = () => {
  return (
    <>
      <Navbar />
      {/* Блок с текстом и формой */}
      <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
        <div className="container mt-24 mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-8">
          {/* Левая колонка с текстом */}
          <div className="lg:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Технологии для стартапов
            </h1>
            <h2 className="text-4xl sm:text-5xl font-bold text-purple-400 mb-6">
              Мы превращаем идеи в успешные продукты
            </h2>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Наша команда специализируется на разработке уникальных решений для
              стартапов — от создания MVP до масштабирования зрелых продуктов.
            </p>
            <ul className="list-disc list-inside text-sm sm:text-base mb-6 leading-relaxed font-light">
              <li>Разработка минимально жизнеспособного продукта (MVP).</li>
              <li>Интеграция с современными технологиями и инструментами.</li>
              <li>Консультации по UX/UI-дизайну и брендингу.</li>
            </ul>
            {/* Кнопка (скрыта на мобильных устройствах) */}
            <a href="#projects" className="btn-outline hidden sm:inline-block">
              Узнать больше
            </a>
          </div>
          {/* Правая колонка с формой */}
          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <QuizForm />
          </div>
        </div>
      </div>
      <StartupFeatures />
      <ComparisonComponent route="/startup" />
      <Stats />

      <Portfolio />
      <AboutMe />
      <TechStack />
      <Testimonials />
      <FAQAccordionStartup />
      <ContactUs />
    </>
  );
};

export default StartupPage;
