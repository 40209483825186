import React, { useState } from "react";

const HRMFeatures = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      title: "Централизация данных сотрудников",
      description:
        "HRM-система объединяет всю ключевую информацию о сотрудниках в одном месте, обеспечивая удобный доступ к данным и их управление.",
    },
    {
      title: "Автоматизация кадровых процессов",
      description:
        "HRM автоматизирует рутинные задачи: управление наймом, расчёт заработной платы, управление отпусками и обработка заявок.",
    },
    {
      title: "Аналитика и отчётность",
      description:
        "Система предоставляет инструменты для анализа производительности, отслеживания удовлетворённости сотрудников и составления детализированных отчётов.",
    },
    {
      title: "Управление талантами",
      description:
        "HRM помогает выявлять таланты в компании, планировать обучение и развитие сотрудников для повышения их эффективности.",
    },
    {
      title: "Интеграция и масштабируемость",
      description:
        "HRM легко интегрируется с системами ERP, CRM и бухгалтерскими платформами, а также масштабируется под растущие потребности бизнеса.",
    },
    {
      title: "Поддержка удалённой работы",
      description:
        "HRM упрощает управление распределёнными командами, обеспечивая инструменты для мониторинга и взаимодействия.",
    },
    {
      title: "Повышение вовлечённости сотрудников",
      description:
        "HRM позволяет организовать эффективное общение между сотрудниками и руководством, улучшая корпоративную культуру.",
    },
  ];

  return (
    <div id="services" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">
            Возможности HRM-системы
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            HRM-система — это незаменимый инструмент для управления персоналом
            и повышения эффективности вашей команды.
          </p>
        </div>

        {/* Список решений */}
        <div>
          {solutions.map((solution, index) => (
            <div
              key={index}
              className={`relative flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 transition ${
                activeIndex === index
                  ? "bg-gradient-to-tr from-[#27134f] to-[#844df5] text-white rounded-lg"
                  : "border-b border-gray-700"
              }`}
              onMouseEnter={() => setActiveIndex(index)}
            >
              {/* Номер и текст */}
              <div className="sm:w-full">
                <h3
                  className={`text-2xl font-bold mb-2 ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  }`}
                >
                  0{index + 1} {solution.title}
                </h3>
                <p
                  className={`text-sm ${
                    activeIndex === index ? "text-gray-200" : "text-gray-400"
                  }`}
                >
                  {solution.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HRMFeatures;
