import React, { useState } from "react";
import Portfolio from "./Portfolio";
import Stats from "./Stats";
import Navbar from "./Navbar";
import TechStack from "./TechStack";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import LogisticsFeatures from "./LogisticsFeatures";
import FAQAccordionLogistics from "./FAQAccordionLogistics";
import AboutMe from "./AboutMe";
import QuizForm from "./QuizForm";
import ComparisonComponent from "./ComparisonComponent";

const WMSPage = () => {

  return (
    <>
      <Navbar />
      {/* Блок с текстом и формой */}
      <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
        <div className="container mt-24 mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-8">
          {/* Левая колонка с текстом */}
          <div className="lg:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Оптимизация логистических процессов
            </h1>
            <h2 className="text-4xl sm:text-5xl font-bold text-purple-400 mb-6">
              WMS, TMS<br />и интеграция данных
            </h2>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Мы создаём системы для управления логистикой, которые помогают
              автоматизировать процессы, оптимизировать маршруты и улучшить
              взаимодействие всех участников цепочки поставок.
            </p>
            <ul className="list-disc list-inside text-sm sm:text-base mb-6 leading-relaxed font-light">
              <li>Эффективное управление складскими процессами (WMS).</li>
              <li>
                Автоматизация управления транспортом и логистическими потоками
                (TMS).
              </li>
              <li>Интеграция данных для управления цепочкой поставок.</li>
            </ul>
            <a href="#projects" className="btn-outline hidden sm:inline-block">
              Узнать больше
            </a>
          </div>
          {/* Правая колонка с формой */}
          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <QuizForm />
          </div>
        </div>
      </div>
      <LogisticsFeatures />
      <ComparisonComponent route="/wms" />
      <Stats />

      <Portfolio />
      <AboutMe />
      <TechStack />
      <Testimonials />
      <FAQAccordionLogistics />
      <ContactUs />
    </>
  );
};

export default WMSPage;
