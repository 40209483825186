import React, { useState } from "react";
import HRMFeatures from "./HRMFeatures"; // Заменено с ERPFeatures на HRMFeatures
import Portfolio from "./Portfolio";
import Stats from "./Stats";
import Navbar from "./Navbar";
import TechStack from "./TechStack";
import Testimonials from "./Testimonials";
import ContactUs from "./ContactUs";
import FAQAccordionHRM from "./FAQAccordionHRM"; // Заменено
import AboutMe from "./AboutMe";
import QuizForm from "./QuizForm";
import ComparisonComponent from "./ComparisonComponent";

const HRMPage = () => {

  return (
    <>
      <Navbar />
      {/* Блок с текстом и формой */}
      <div className="relative bg-[#0f0715] text-white px-4 py-10 sm:px-6 lg:px-8">
        <div className="container mt-24 mx-auto max-w-7xl flex flex-col lg:flex-row items-center gap-8">
          {/* Левая колонка с текстом */}
          <div className="lg:w-1/2">
            <h1 className="text-3xl sm:text-4xl font-bold mb-4">
              Комплексные решения для управления персоналом
            </h1>
            <h2 className="text-4xl sm:text-5xl font-bold text-purple-400 mb-6">
              HRM-системы
              <br />
              для вашего бизнеса
            </h2>
            <p className="text-sm sm:text-base mb-6 leading-relaxed font-light">
              Мы создаём HRM-системы, которые помогают автоматизировать процессы управления персоналом, улучшить взаимодействие сотрудников и повысить их вовлеченность.
            </p>
            <ul className="list-disc list-inside text-sm sm:text-base mb-6 leading-relaxed font-light">
              <li>Централизация данных сотрудников в единой системе.</li>
              <li>
                Автоматизация процессов найма, обучения и оценки сотрудников.
              </li>
              <li>Интеграция с существующими бизнес-системами.</li>
            </ul>
            {/* Кнопка (скрыта на мобильных устройствах) */}
            <a href="#projects" className="btn-outline hidden sm:inline-block">
              Узнать больше
            </a>
          </div>
          {/* Правая колонка с формой */}
          <div className="lg:w-1/2 flex justify-center lg:justify-end">
            <QuizForm />
          </div>
        </div>
      </div>
      <HRMFeatures /> 
      <ComparisonComponent route="/hrm" /> 
      <Stats />
      <Portfolio />
      <AboutMe />
      <TechStack />
      <Testimonials />
      <FAQAccordionHRM />
      <ContactUs />
    </>
  );
};

export default HRMPage;
