import React, { useState } from "react";

const EcommerceFeatures = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      title: "Удобный интерфейс",
      description:
        "Создание интуитивно понятного интерфейса для покупателей и продавцов, который обеспечивает комфортный пользовательский опыт.",
    },
    {
      title: "Интеграция с платежными системами",
      description:
        "Подключение популярных способов оплаты, таких как банковские карты, электронные кошельки и криптовалюты.",
    },
    {
      title: "Интеграция с логистикой",
      description:
        "Автоматизация расчёта стоимости доставки, отслеживания заказов и интеграция со службами доставки.",
    },
    {
      title: "Масштабируемость",
      description:
        "Платформы, которые легко масштабируются при увеличении нагрузки, количества пользователей или ассортимента.",
    },
    {
      title: "Управление каталогом товаров",
      description:
        "Инструменты для простого управления товарами: добавление, редактирование, категории и фильтры.",
    },
    {
      title: "Аналитика и отчёты",
      description:
        "Системы аналитики для отслеживания продаж, поведения пользователей и ключевых бизнес-метрик.",
    },
    {
      title: "Программа лояльности и акции",
      description:
        "Функционал для создания скидок, программ лояльности, акций и кэшбэков для удержания клиентов.",
    },
  ];

  return (
    <div id="services" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">Возможности E-commerce решений</h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Мы создаём современные интернет-магазины и маркетплейсы, которые помогают вашему бизнесу расти.
          </p>
        </div>

        {/* Список решений */}
        <div>
          {solutions.map((solution, index) => (
            <div
              key={index}
              className={`relative flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 transition ${
                activeIndex === index
                  ? "bg-gradient-to-tr from-[#27134f] to-[#844df5] text-white rounded-lg"
                  : "border-b border-gray-700"
              }`}
              onMouseEnter={() => setActiveIndex(index)}
            >
              {/* Номер и текст */}
              <div className="sm:w-full">
                <h3
                  className={`text-2xl font-bold mb-2 ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  }`}
                >
                  0{index + 1} {solution.title}
                </h3>
                <p
                  className={`text-sm ${
                    activeIndex === index ? "text-gray-200" : "text-gray-400"
                  }`}
                >
                  {solution.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EcommerceFeatures;
