import React, { useState } from "react";

const LogisticsFeatures = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const solutions = [
    {
      title: "Управление складом (WMS)",
      description:
        "WMS-системы автоматизируют управление складскими операциями: приёмка, хранение, сборка заказов и отгрузка.",
    },
    {
      title: "Оптимизация маршрутов (TMS)",
      description:
        "TMS помогает планировать маршруты, сокращать время доставки и минимизировать транспортные расходы.",
    },
    {
      title: "Интеграция данных",
      description:
        "Объединение данных из различных систем для улучшения взаимодействия и контроля в цепочке поставок.",
    },
    {
      title: "Реальное время",
      description:
        "Мониторинг и управление логистическими процессами в реальном времени для повышения их прозрачности.",
    },
    {
      title: "Аналитика и прогнозирование",
      description:
        "Системы предоставляют инструменты анализа данных и прогнозирования, позволяя оптимизировать логистику.",
    },
    {
      title: "Поддержка многомодальности",
      description:
        "Инструменты для управления различными типами транспорта: грузовики, железнодорожные перевозки, морские суда и авиатранспорт.",
    },
    {
      title: "Автоматизация документов",
      description:
        "Упрощение и ускорение обработки документов: накладные, счета-фактуры, разрешения на перевозку и прочее.",
    },
  ];

  return (
    <div id="services" className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container mx-auto max-w-7xl">
        {/* Заголовок */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold mb-4">
            Возможности логистических систем
          </h2>
          <p className="text-gray-400 text-lg max-w-2xl mx-auto">
            Современные решения для управления складом, транспортом и всей
            цепочкой поставок.
          </p>
        </div>

        {/* Список решений */}
        <div>
          {solutions.map((solution, index) => (
            <div
              key={index}
              className={`relative flex flex-col sm:flex-row items-start sm:items-center justify-between p-6 transition ${
                activeIndex === index
                  ? "bg-gradient-to-tr from-[#27134f] to-[#844df5] text-white rounded-lg"
                  : "border-b border-gray-700"
              }`}
              onMouseEnter={() => setActiveIndex(index)}
            >
              {/* Номер и текст */}
              <div className="sm:w-full">
                <h3
                  className={`text-2xl font-bold mb-2 ${
                    activeIndex === index ? "text-white" : "text-purple-400"
                  }`}
                >
                  0{index + 1} {solution.title}
                </h3>
                <p
                  className={`text-sm ${
                    activeIndex === index ? "text-gray-200" : "text-gray-400"
                  }`}
                >
                  {solution.description}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LogisticsFeatures;
