import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage";
import CrmPage from "./pages/CrmPage";
import WMSPage from "./pages/WMSPage";
import HRMPage from "./pages/HRMPage";
import Web3Page from "./pages/Web3Page";
import ECommercePage from "./pages/ECommercePage";
import StartupPage from "./pages/StartupPage";
import IndexPage from "./pages/IndexPage";
const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexPage />} />
        <Route path="/erp" element={<HomePage />} />
        <Route path="/crm" element={<CrmPage />} />
        <Route path="/wms" element={<WMSPage />} />
        <Route path="/hrm" element={<HRMPage />} />
        <Route path="/btc" element={<Web3Page />} />
        <Route path="/ecommerce" element={<ECommercePage />} />
        <Route path="/startup" element={<StartupPage />} />
      </Routes>
    </Router>
  );
};

export default App;
