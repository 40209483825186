import React, { useState } from "react";

const faqs = [
  {
    question: "Какова цена услуги по разработке CRM системы?",
    answer:
      "Цена разработки CRM системы начинается от 300 тысяч рублей. Стоимость варьируется в зависимости от сложности, функциональности и индивидуальных требований вашего бизнеса.",
  },
  {
    question: "Каковы преимущества услуги по разработке CRM системы с нуля?",
    answer:
      "Создание CRM системы с нуля позволяет полностью адаптировать её под ваши уникальные бизнес-процессы, включая гибкость интеграций, масштабируемость и повышение эффективности работы с клиентами.",
  },
  {
    question: "Каковы сроки разработки CRM системы?",
    answer:
      "Средний срок разработки CRM системы составляет от 1 до 3 месяцев. Конкретные сроки зависят от ваших требований, сложности задач и объема интеграций.",
  },
  {
    question: "Какие интеграции можно реализовать в CRM системе?",
    answer:
      "CRM система может быть интегрирована с ERP, телефонией, почтовыми сервисами, системами аналитики, мессенджерами, платформами автоматизации маркетинга и многими другими инструментами.",
  },
  {
    question: "Какой уровень поддержки и обслуживания предоставляется после создания CRM?",
    answer:
      "Мы предоставляем круглосуточную техническую поддержку, регулярные обновления и помощь в оптимизации вашей CRM системы после её внедрения.",
  },
  {
    question: "Как начать процесс создания CRM?",
    answer:
      "Свяжитесь с нами, чтобы обсудить ваши задачи и цели. Мы проведём аудит процессов, подготовим техническое задание и приступим к разработке вашей уникальной CRM системы.",
  },
];

const FAQAccordionCRM = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <section className="bg-[#0f0715] text-white py-16 px-4">
      <div className="container max-w-7xl mx-auto">
        <h2 className="text-4xl font-bold text-purple-400 mb-6">
          Часто задаваемые вопросы о разработке CRM
        </h2>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <div
              key={index}
              className="bg-[#140c1c] rounded-lg shadow-lg overflow-hidden"
            >
              <button
                onClick={() => toggleAccordion(index)}
                className="w-full flex justify-between items-center px-6 py-4 text-left font-semibold text-lg hover:bg-[#27203e] transition"
              >
                <span>{faq.question}</span>
                <span>
                  {activeIndex === index ? (
                    <i className="fas fa-chevron-up text-purple-400"></i>
                  ) : (
                    <i className="fas fa-chevron-down text-purple-400"></i>
                  )}
                </span>
              </button>
              {activeIndex === index && (
                <div className="px-6 py-4 text-gray-300">
                  <p>{faq.answer}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQAccordionCRM;
